<template>
    <div>
        <CRow>
            <CCol lg="12">
                <!-- <CButton
                    @click="goBack()"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton> -->
                 <router-link
                      :to="{
                          path: `/${$store.getters['getOrganizationName']}/drs/routing-su`,
                          query: {searchParams :searchParams, selectedToggled : selected_toggled},
                      }"
                      replace
                      >
                  <CButton size="sm"
                              color="warning" >
                      <font-awesome-icon icon="arrow-left"/> Go back
                  </CButton>
              </router-link>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol lg="6">
                                <h5><font-awesome-icon icon="cog"/> Manage Routing: {{ d_informations_backup.reference_number }}</h5>
                            </CCol>
                            <CCol lg="6">
                                <h5 class="float-right">Document Status:
                                    <CBadge :color="getColorStatus(d_informations_backup.status == 1 ? 'draft' :
                                        d_informations_backup.status == 3 ? 'completed' :
                                        d_informations_backup.status == 4 ? 'cancelled' : 'signing')">
                                    {{d_informations_backup.status == 1 ? 'DRAFT' :
                                        d_informations_backup.status == 3 ? 'COMPLETED' :
                                        d_informations_backup.status == 4 ? 'CANCELLED' : 'SIGNING' }}        
                                    </CBadge>
                                </h5>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs 
                            addNavWrapperClasses="col-sm-3 col-md-3 col-lg-3" 
                            addTabsWrapperClasses="col-sm-9 col-md-9 col-lg-9" 
                            fade 
                            vertical 
                            variant="pills"
                            :active-tab="active_tab" 
                            @update:activeTab="changeTab"
                        >
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="info-circle"/>
                                    Document Informations
                                </template>
                                <CRow>
                                    <!-- <CCol lg="6">
                                        <h5>Document Status: 
                                            <CBadge :color="getColorStatus(d_informations.status == 1 ? 'draft' :
                                                d_informations.status == 3 ? 'completed' :
                                                d_informations.status == 4 ? 'cancelled' : 'signing')">
                                            {{d_informations.status == 1 ? 'DRAFT' :
                                                d_informations.status == 3 ? 'COMPLETED' :
                                                d_informations.status == 4 ? 'CANCELLED' : 'SIGNING' }}        
                                            </CBadge>

                                        </h5>
                                    </CCol> -->
                                    <CCol lg="12" v-if="!data_loading">
                                        <template>
                                            <CButton 
                                                v-if="$store.getters['can']('routing-su-cancel-document')
                                                            && d_informations_backup.status == 1"
                                                size="sm"
                                                color="danger" 
                                                v-show="!edit_info"
                                                @click="cancelDocumentRouting()"
                                                class="float-left" 
                                            >
                                                <font-awesome-icon icon="window-close"/> Cancel Routing
                                            </CButton>
                                        </template>
                                        <template>
                                            <CButton 
                                                @click="toggleEdit(), returnInformation()" 
                                                size="sm"
                                                color="danger" 
                                                class="float-right" 
                                                v-show="edit_info"
                                            >
                                                <font-awesome-icon icon="window-close"/> Cancel
                                            </CButton>
                                            <CButton 
                                                v-if="$store.getters['can']('routing-su-update-document-information')"
                                                size="sm"
                                                color="success" 
                                                class="float-right" 
                                                @click="updateDocumentInformation()"
                                                v-show="edit_info" 
                                                style="margin-right: 3px;"
                                            >
                                                <font-awesome-icon icon="save"/> Update
                                            </CButton>
    
                                            <CButton 
                                                v-if="$store.getters['can']('routing-su-add-external-attachment')"
                                                size="sm"
                                                color="info" 
                                                class="float-right" 
                                                @click="toggleEdit()" 
                                                v-show="!edit_info"
                                            >
                                                <font-awesome-icon icon="edit"/> Edit
                                            </CButton>
                                        </template>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="6">
                                        <CRow>
                                            <CCol lg="12">
                                                <CInput
                                                    label="Reference Number" 
                                                    type="text" 
                                                    :disabled="!edit_info"
                                                    placeholder="100-8953245" 
                                                    ondrop="return false;" 
                                                    autocomplete="off"  
                                                    v-nospecialcharacter
                                                    v-tocapitalize
                                                    v-model="d_informations.reference_number"
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol lg="12">
                                                <CTextarea
                                                    class="grp-text-area"
                                                    label="Description" 
                                                    :disabled="!edit_info"
                                                    type="text" 
                                                    placeholder="Enter brief description..." 
                                                    autocomplete="off"  
                                                    v-model="d_informations.description"
                                                />
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                    <CCol lg="6">
                                        <label>Document File &nbsp;</label>
                                        <!-- :disabled="!edit_info" -->
                                        <!-- :deletable="true" -->

                                        <VueFileAgent 
                                            ref="_templateFile"
                                            :theme="'list'" 
                                            :multiple="true"
                                            :maxFiles="!edit_info ? 1 : d_informations.status != 1 ? 1 : 10"
                                            :maxSize="'8MB'"
                                            :meta="true"
                                            :accept="'application/pdf'"
                                            :linkable="true"
                                            @beforedelete="removeMainFile($event)"
                                            v-model="d_informations.document_file"
                                        >
                                        </VueFileAgent>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="signature"/>
                                    Template & Signatories
                                </template>
                                <CRow>
                                    <CCol lg="6">
                                        <CInput
                                            label="Document Template" 
                                            type="text" 
                                            readonly
                                            placeholder="March JO - Routing" 
                                            autocomplete="off"  
                                            v-model="d_template_and_signatories.template_name"
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                       <CButton 
                                            :title="!$store.getters['can']('routing-su-add-signatory') ? 'No permission to add.'  // condish 1
                                                        : ![1, 2].includes(parseInt(d_informations_backup.status)) ? 'Adding of Signatory is not allowed in completed and cancelled documents.'  // condish 2
                                                            : ''"
                                            v-if="!data_loading"
                                           class="float-left"
                                           :disabled="!$store.getters['can']('routing-su-add-signatory') 
                                                        || (adding_signatory ?  true : false) 
                                                            || ![1, 2].includes(parseInt(d_informations_backup.status))"
                                           @click="$store.getters['can']('routing-su-add-signatory') 
                                                        ? ([1, 2].includes(parseInt(d_informations_backup.status)) ? addSignatory() : '') 
                                                            : ''"
                                           color="success"
                                       >
                                           <font-awesome-icon 
                                                :icon="adding_signatory ? 'circle-notch' : 'user-plus'" 
                                                :pulse="adding_signatory ? true : false"
                                           /> Add Signatory
                                       </CButton>
                                       <template v-if="d_informations_backup.status == 2">
                                           <CButton 
                                               v-if="!data_loading && $store.getters['can']('routing-su-return-to-signatory')"
                                               color="warning"
                                               size="sm"
                                               @click="signed_signatories.length > 0 ? returnToSignatory() : ''"
                                               :disabled="signed_signatories.length <= 0 || returning_to_signatory "
                                                :title="signed_signatories.length <= 0 ? 'No signatories that signed the document yet.' : ''"
                                               class="float-right"
                                           >
                                               <font-awesome-icon 
                                                       :icon="returning_to_signatory ? 'circle-notch' : 'backspace'" 
                                                       :pulse="returning_to_signatory ? true : false"
                                               /> Return To Signatory
                                           </CButton> 
                                           &nbsp;
                                          <CButton 
                                               v-if="!data_loading && $store.getters['can']('routing-su-return-to-draft')"
                                               color="dark"
                                               size="sm"
                                               :disabled="returning_to_draft"
                                               style="margin-right:10px"
                                               @click="returnToDraft()"
                                               class="float-right"
                                           >
                                               <font-awesome-icon 
                                                       :icon="returning_to_draft ? 'circle-notch' : 'undo-alt'" 
                                                       :pulse="returning_to_draft ? true : false"
                                               /> Return To Draft
                                           </CButton>
                                           
                                        </template>
                                        <template v-if="d_informations_backup.status == 1 || d_informations_backup.status == 2">
                                           <CButton 
                                               v-if="!data_loading && display_swap_button && !is_swapping && $store.getters['can']('routing-su-swap-signatory')"
                                               color="primary"
                                               size="sm"
                                               style="margin-right:10px"
                                               @click="activateSwap()"
                                               class="float-right"
                                           >
                                               <font-awesome-icon 
                                                       icon="retweet"
                                               /> Swap Signatories
                                           </CButton>
                                           <CButton 
                                               v-if="!data_loading && display_swap_button && is_swapping && $store.getters['can']('routing-su-swap-signatory')"
                                               color="danger"
                                               size="sm"
                                               style="margin-right:10px"
                                               @click="cancelSwapSignatory()"
                                               class="float-right"
                                           >
                                               <font-awesome-icon icon="times"
                                               /> Cancel Swap Signatories
                                           </CButton> 
                                        </template>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <!-- <label>Signatories</label> -->
                                        <CDataTable
                                            :items="d_template_and_signatories.signatories"
                                            :fields="signatories_field"
                                            border
                                            hover
                                            sorter
                                            size="sm"
                                            :loading="data_loading"
                                            clickableRows
                                            addTableClasses="signatories_table"
                                        >
                                            <template slot="no-items-view" v-if="data_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            <template #action="{item, index}">
                                                <td>
                                                    <template v-if="!item.cancelled_at && !item.signed_at">
                                                        <!-- v-if="item.id != first_signatory_line_id" -->
                                                        <!-- v-if="item.id != last_signatory_id" -->
                                                        <CButton
                                                            block
                                                            :title="!$store.getters['can']('routing-su-remove-signatory') ? 'No permission to remove.' : (!has_signed_signatory && item.id == last_signatory_id) ? 'Cannot remove last signatory because other signatory doesnt sign the routing.' : ''"
                                                            :disabled="!$store.getters['can']('routing-su-remove-signatory') || (!has_signed_signatory && item.id == last_signatory_id)"
                                                            color="danger"
                                                            size="sm"
                                                            shape="pill" 
                                                            @click="$store.getters['can']('routing-su-remove-signatory') || 
                                                                (has_signed_signatory && item.id != last_signatory_id) ? removeSignatory(item, index) : ''"
                                                        >
                                                            <font-awesome-icon icon="file"/> Remove Signatory
                                                        </CButton>
                                                        <!-- !$store.getters['can']('routing-su-change-signatory') -->
                                                        <CButton
                                                            block
                                                            :title="!$store.getters['can']('routing-su-change-signatory') ? 'No permission to change.' : ''"
                                                            :disabled="(changing_signatory ? (item.id == changing_signatory_id ? true : false) : false) || !$store.getters['can']('routing-su-change-signatory')"
                                                            color="dark"
                                                            size="sm"
                                                            shape="pill" 
                                                            @click="$store.getters['can']('routing-su-change-signatory') ? changeSignatory(item, index) : ''"
                                                        >
                                                            <font-awesome-icon 
                                                                :icon="(changing_signatory ? (item.id == changing_signatory_id ? 'circle-notch' : 'user-slash') : 'user-slash')" 
                                                                :pulse="changing_signatory ? (item.id == changing_signatory_id ? true : false) : false"
                                                            />
                                                            Change Signatory
                                                        </CButton>
                                                    </template>
                                                    <template v-else>
                                                        ----
                                                    </template>
                                                </td>
                                            </template>
                                            <template #requirements="{item, index}">
                                                <td v-if="item.document_signature_requirements.length > 0">
                                                    <center>
                                                        <CButton
                                                            :color="Boolean(item._toggled) ? 'danger' : 'info'"
                                                            shape="pill" 
                                                            size="sm"
                                                            @click="toggleDetails(item, index)"
                                                        >
                                                            <font-awesome-icon :icon="Boolean(item._toggled) ? 'eye-slash' : 'eye'"/> {{Boolean(item._toggled) ? 'Hide Requirements' : 'Show Requirements'}}
                                                        </CButton>
                                                    </center>
                                                </td>
                                                <td v-if="item.document_signature_requirements.length <= 0">
                                                    <i>N/a</i>
                                                </td>
                                            </template>
                                            <template #signed={item}>
                                                <td>
                                                    <template v-if="!item.cancelled_at">
                                                        <template v-if="item.signed_at">
                                                            
                                                            <label style="color: green;">
                                                                <font-awesome-icon icon="check" />  
                                                                Signed
                                                            </label> 
                                                            <br>
                                                            <small>
                                                                <font-awesome-icon icon="calendar-check" /> 
                                                                {{changeDateFormat(item.signed_at)}}
                                                            </small>
                                                        </template>
                                                        <template v-if="!item.signed_at">
                                                            ----
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <label style="color:red;">
                                                            <font-awesome-icon icon="times" /> 
                                                            Removed
                                                        </label> 
                                                        <br>
                                                        <small>
                                                            <font-awesome-icon icon="calendar-times"/>  
                                                            {{changeDateFormat(item.cancelled_at)}}
                                                        </small>
                                                    </template>
                                                </td>
                                            </template>
                                            <template #required_signature={item}>
                                                <td>
                                                    <!-- <center>
                                                        <p-check 
                                                            disabled
                                                            class="p-icon p-bigger" 
                                                            color="success" 
                                                            off-color="danger"
                                                            toggle
                                                            v-model="item.document_template_signature.required_signature"
                                                        >
                                                            
                                                            <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                            <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                            
                                                            <label slot="off-label"></label>
                                                        </p-check>
                                                    </center> -->
                                                    
                                                    <i>{{ item.document_template_signature.required_signature ? 'Yes' : 'No' }}</i>
                                                </td>
                                            </template>
                                            <template #name={item}>
                                                <td>
                                                    {{item.document_template_signature.user.first_name}}
                                                    {{item.document_template_signature.user.last_name}}
                                                </td>
                                            </template>
                                            <template #user_id={item}>
                                                <td class="d-none">
                                                    {{item.document_template_signature.user_id}}
                                                </td>
                                            </template>
                                            <template #line_id={item}>
                                                <td class="d-none">
                                                    {{item.id}}
                                                </td>
                                            </template>
                                            <template #purpose={item}>
                                                <td>
                                                    {{item.document_template_signature.purpose}}
                                                </td>
                                            </template>
                                            <template #details="{item}">
                                                <CCollapse :show="Boolean(item._toggled)">
                                                    <CCard border-color="info">
                                                        <CCardHeader><font-awesome-icon icon="paperclip"/> Signature Requirements for {{item.document_template_signature.user.first_name}} {{item.document_template_signature.user.last_name}}
                                                        </CCardHeader>
                                                        <CCardBody>
                                                            <CListGroup>
                                                                <CListGroupItem v-for="(child_item, index) in item.document_signature_requirements" v-bind:key="child_item.requirement">
                                                                {{ index+1 }}. <strong>{{child_item.requirement}}</strong>
                                                                </CListGroupItem>
                                                            </CListGroup>
                                                        </CCardBody>
                                                    </CCard>
                                                </CCollapse>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="file-upload"/>
                                    External Attachments
                                </template>
                                <CRow>
                                    <CCol lg="6">
                                        <input 
                                            v-if="!data_loading && $store.getters['can']('routing-su-add-external-attachment') && d_informations_backup.status != 4"
                                            ref="_externalFiles" 
                                            type="file" 
                                            multiple
                                            v-on:change="onFileChange"
                                            :accept="'image/*,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                        >
                                    </CCol>
                                    <CCol lg="6">
                                        <CButton
                                            @click="saveExternalAttachment()"
                                            class="float-right"
                                            size="sm"
                                            color="success" 
                                            v-if="d_external_attachments.filter(x => !x.id).length > parseInt('0')"
                                        > 
                                            <font-awesome-icon icon="save"/> Save Attachment
                                        </CButton>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="d_external_attachments"
                                            :fields="external_attachments_field"
                                            border
                                            hover
                                            :key="d_external_attachments_key"
                                            sorter
                                            size="sm"
                                            :loading="data_loading"
                                        >
                                            <template slot="no-items-view" v-if="data_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            <template #filetype="{item}"> 
                                                <td v-if="item.id">
                                                    <a :href="item.url" target="_blank">
                                                        <center>
                                                            <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.file_mime.split('/')[0] == 'image'"/> 
                                                            <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.file_mime == 'application/pdf'"/> 
                                                            <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.file_mime == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.file_mime == 'application/vnd.ms-excel'"/> 
                                                        </center>
                                                    </a>
                                                </td>
                                                <td v-else>
                                                    <center>
                                                        <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.file_mime.split('/')[0] == 'image'"/> 
                                                        <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.file_mime == 'application/pdf'"/> 
                                                        <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.file_mime == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.file_mime == 'application/vnd.ms-excel'"/> 
                                                    </center>
                                                </td>
                                            </template>
                                            <template #filesize="{item}">
                                                <td>
                                                    {{ formatBytes(item.file_size) }}
                                                </td>
                                            </template>
                                            <template #attachment="{item}">
                                                <td>
                                                    {{ item.document_template_category_set_attachment_checklist?.name }}
                                                </td>
                                            </template>
                                            <template #filename="{item}">
                                                <td :title="item.name">
                                                    <template v-if="item.id">
                                                        {{ item.name.length > 15 ? item.name.substring(0, 15) + '...' +  item.file_ext : item.name +'.'+ item.file_ext}}
                                                    </template>
                                                    <template v-else>
                                                        {{ item.name.length > 15 ? item.name.substring(0, 15) + '...' +  item.name.split('.').pop() : item.name }} 
                                                    </template>
                                                </td>
                                            </template>
                                            <template #remarks="{item}">
                                                <td :title="item.remarks">
                                                    <template v-if="item.id">
                                                        {{ item.remarks.length > 50 ? item.remarks.substring(0, 50) + '...' : item.remarks }}
                                                    </template>
                                                    <template v-else>
                                                        <CTextarea
                                                            class="grp-text-area"
                                                            placeholder="Remarks.."
                                                            autocomplete="off"  
                                                            v-tocapitalize
                                                            v-model="item.remarks"
                                                        /> 
                                                    </template>
                                                </td>
                                            </template>
                                            <template #date_uploaded="{item}">
                                                <td>
                                                    <template v-if="item.id">
                                                        <small>{{ changeDateFormatV2(item.created_at) }}</small>
                                                    </template>
                                                    <template v-else>
                                                        <small>
                                                            <CBadge color="success">Recently Added</CBadge>
                                                        </small>
                                                    </template>
                                                </td>
                                            </template>
                                            <template #uploaded_by="{item}">
                                                <td>
                                                    {{ item.user.first_name }}
                                                </td>
                                            </template>
                                            <template #action="{item, index}">
                                                <td>
                                                    <center>
                                                        <CButton 
                                                            color="danger" 
                                                            shape="pill" 
                                                            :disabled="removing_external_attachment ? (item.id == removing_external_attachment_id ? true : false) : false"
                                                            size="sm"
                                                            @click="removeExternalAttachment(item, index)"
                                                        >
                                                            <font-awesome-icon 
                                                                :icon="(removing_external_attachment ? (item.id == removing_external_attachment_id ? 'circle-notch' : (item.id ? 'trash': 'times')) : (item.id ? 'trash': 'times'))" 
                                                                :pulse="removing_external_attachment ? (item.id == removing_external_attachment_id ? true : false) : false"
                                                            />
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="file-download"/>
                                    Internal Attachments
                                </template>
                                <CRow v-if="!data_loading && $store.getters['can']('routing-su-add-internal-attachment') && d_informations_backup.status != 4">
                                    <CCol lg="6">
                                        <CRow>
                                            <CCol lg="12">
                                                <label>Select Category</label>
                                                <v-select 
                                                    label="category"
                                                    :options="document_categories"
                                                    v-model="chosen.cat"
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol lg="12">
                                                <CTextarea
                                                    class="grp-text-area-cust"
                                                    placeholder="Remarks.."
                                                    label="Enter remarks"
                                                    autocomplete="off"  
                                                    v-tocapitalize
                                                    v-model="chosen.rmks"
                                                />
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                    <CCol lg="6">
                                        <CRow>
                                            <CCol lg="12">
                                                <!-- v-model="selected_reference" -->

                                                <CInput 
                                                    readonly
                                                    label="Routing Reference Number" 
                                                    v-model="chosen.rrn"
                                                >
                                                    <template #append >
                                                        <CButton 
                                                            color="primary"
                                                            :disabled="is_getting_rrn"
                                                            @click="getRoutingReferenceNumber()"
                                                        >
                                                            <font-awesome-icon :icon="is_getting_rrn ? 'circle-notch' :'search'"
                                                            :pulse="is_getting_rrn"/>
                                                        </CButton>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                        </CRow>
                                        
                                        <CRow>
                                            <CCol lg="12">
                                                <CDropdown
                                                    color="info"
                                                    :togglerText="is_getting_file_to_add_internally ? 'Getting...' : 'Get File(s)'" 
                                                    style="margin-top: 10px"
                                                    :disabled="!chosen.cat || !chosen.rrn || is_getting_file_to_add_internally"
                                                >
                                                    <CDropdownItem @click="getFileOfRouting('main_file')">Main File</CDropdownItem>
                                                    <CDropdownItem @click="getFileOfRouting('all_file')">All File(s) - Attachments included</CDropdownItem>
                                                </CDropdown>

                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="6"/>
                                    <CCol lg="6">
                                        <CButton
                                            @click="saveInternalAttachment()"
                                            class="float-right"
                                            size="sm"
                                            color="success" 
                                            v-if="d_internal_attachments.filter(x => !x.id && !x.parent).length > parseInt('0')"
                                        > 
                                            <font-awesome-icon icon="save"/> Save Attachment
                                        </CButton>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :key="d_internal_attachments_key"
                                            :items="d_internal_attachments"
                                            :fields="internal_attachments_fields"
                                            border
                                            hover
                                            size="sm"
                                            sorter
                                            pagination
                                        >
                                            <template #uploaded_by="{item}"> 
                                                <td v-if="!item.parent">
                                                    {{ item.uploaded_by }}
                                                </td>
                                                <td v-else></td>
                                            </template>
                                            <template #date_uploaded="{item}">
                                                <td v-if="!item.parent"> 
                                                    <template v-if="item.id">
                                                        <small>{{ item.date_uploaded }}</small>
                                                    </template>
                                                    <template v-else>
                                                        <small>
                                                            <CBadge color="success">Recently Added</CBadge>
                                                        </small>
                                                    </template>
                                                </td>
                                                <td v-else></td>
                                            </template>
                                            <template #filetype="{item}">
                                                <td>
                                                    <center>
                                                        <a :href="item.url" target="_blank">
                                                            <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.filetype.split('/')[0] == 'image'"/> 
                                                            <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.filetype == 'application/pdf'"/> 
                                                            <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.filetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                        </a>
                                                    </center>
                                                </td>
                                            </template>
                                            <template #remarks="{item}">
                                                <td :title="item.remarks">
                                                    <template v-if="item.id">
                                                        {{ item.remarks.length > 50 ? item.remarks.substring(0, 50) + '...' : item.remarks }}
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="!item.parent">
                                                            <CTextarea
                                                                class="grp-text-area"
                                                                placeholder="Remarks.."
                                                                autocomplete="off"  
                                                                v-tocapitalize
                                                                v-model="item.remarks"
                                                            /> 
                                                        </template>
                                                        <template v-else/>
                                                    </template>
                                                </td>
                                            </template>
                                            <template #checklist="{item}">
                                                <td v-if="!item.parent">
                                                    {{ item.checklist?.name }}
                                                </td>
                                                <td v-else></td>
                                            </template>
                                            <template #filename="{item}">
                                                <td v-if="item.parent">
                                                    <strong>{{item.reference_number}}</strong>
                                                </td>
                                                <td v-else-if="item.main" :title="item.filename+'.'+item.fileext">
                                                    <small><i>Main File</i></small><br>
                                                    {{ item.filename.length > 30 ? item.filename.substring(0, 30) + '...' : item.filename}}{{'.'+item.fileext}}
                                                </td>
                                                <td v-else :title="item.filename+'.'+item.fileext">
                                                    <small><i>Supporting File(s)</i></small><br>
                                                    {{ item.filename.length > 30 ? item.filename.substring(0, 30) + '...' : item.filename}}{{'.'+item.fileext}}
                                                </td>
                                            </template>
                                            <template #action="{item, index}">
                                                <td v-if="item.parent">
                                                    <!-- <center>
                                                        <CButton 
                                                            color="danger" 
                                                            shape="pill" 
                                                            title="Remove Attachment"
                                                            size="sm"
                                                            @click="removeInternalFileV2('parent_file', item, index)"
                                                        >
                                                            Remove Attachment
                                                        </CButton>
                                                    </center> -->
                                                </td>
                                                <td v-else-if="item.main">
                                                    <center>
                                                        <CButton 
                                                            :disabled="removing_internal_attachment ? (item.id == removing_internal_attachment_id ? true : false) : false"
                                                            color="danger" 
                                                            shape="pill" 
                                                            title="Remove Attachment"
                                                            @click="removeInternalFileV2('main_file', item, index)"
                                                            size="sm"
                                                        >
                                                        <font-awesome-icon 
                                                            :icon="(removing_internal_attachment ? (item.id == removing_internal_attachment_id ? 'circle-notch' : (item.id ? 'trash': 'times')) : (item.id ? 'trash': 'times'))" 
                                                            :pulse="removing_internal_attachment ? (item.id == removing_internal_attachment_id ? true : false) : false"
                                                        />
                                                        </CButton>
                                                    </center>
                                                </td>
                                                <td v-else>
                                                    <center>
                                                        <CButton 
                                                            :disabled="removing_internal_attachment ? (item.id == removing_internal_attachment_id ? true : false) : false"
                                                            color="danger" 
                                                            shape="pill" 
                                                            title="Remove Attachment"
                                                            @click="removeInternalFileV2('supporting_file', item, index)"
                                                            size="sm"
                                                        >
                                                        <font-awesome-icon 
                                                            :icon="(removing_internal_attachment ? (item.id == removing_internal_attachment_id ? 'circle-notch' : (item.id ? 'trash': 'times')) : (item.id ? 'trash': 'times'))" 
                                                            :pulse="removing_internal_attachment ? (item.id == removing_internal_attachment_id ? true : false) : false"
                                                        />
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                        </CDataTable>











                                        <!-- <CDataTable
                                            :items="d_internal_attachments"
                                            :fields="internal_attachments_fields"
                                            border
                                            hover
                                            sorter
                                            size="sm"
                                            :key="d_internal_attachments_key"
                                            :loading="data_loading"
                                        >
                                            <template slot="no-items-view" v-if="data_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            <template #uploaded_by="{item}">
                                                <td>
                                                    {{ item.uploaded_by.first_name }}
                                                </td>
                                            </template>
                                            <template #reference_number="{item}">
                                                <td :title="item.reference_number">
                                                    {{item.reference_number.length > 15 ? item.reference_number.substring(0, 15) + '...' : item.reference_number }}
                                                </td>
                                            </template>
                                            <template #remarks="{item}">
                                                <td :title="item.remarks">
                                                    <template v-if="item.status == 'old'">
                                                        {{ item.remarks.length > 50 ? item.remarks.substring(0, 50) + '...' : item.remarks }}
                                                    </template>
                                                    <template v-else>
                                                        <CTextarea
                                                            class="grp-text-area"
                                                            placeholder="Remarks.."
                                                            autocomplete="off"  
                                                            v-tocapitalize
                                                            v-model="item.remarks"
                                                        /> 
                                                    </template>
                                                </td>
                                            </template>
                                            <template #date_uploaded="{item}"> 
                                                <td>
                                                    <template v-if="item.status == 'old'">
                                                        <small>{{ changeDateFormatV2(item.date_uploaded) }}</small>
                                                    </template>
                                                    <template v-else>
                                                        <small>
                                                            <CBadge color="success">Recently Added</CBadge>
                                                        </small>
                                                    </template>
                                                </td>
                                            </template>
                                            <template #action="{item, index}"> 
                                                <td>
                                                    <center>
                                                        <CButton
                                                            :color="Boolean(item._toggled) ? 'danger' : 'info'"
                                                            shape="pill" 
                                                            size="sm"
                                                            @click="toggleInternalAttachment(item, index)"
                                                        >
                                                            <font-awesome-icon :icon="Boolean(item._toggled) ? 'eye-slash' : 'eye'"/> {{Boolean(item._toggled) ? 'Hide File(s)' : 'Show File(s)'}}
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                            <template #details="{item, index}">
                                                <CCollapse :show="Boolean(item._toggled)">
                                                    <CCard border-color="info">
                                                        <CCardHeader><font-awesome-icon icon="paperclip"/> Internal Attachment of <b>{{item.reference_number}}</b>
                                                        </CCardHeader>
                                                        <CCardBody>
                                                            <CRow v-for="(child_item, child_item_index) in item.attachments" v-bind:key="child_item.file">
                                                                <CCol lg="1">
                                                                    <center>
                                                                        <a :href="child_item.url" target="_blank">
                                                                            <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="child_item.file_mime.split('/')[0] == 'image'"/> 
                                                                            <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="child_item.file_mime == 'application/pdf'"/> 
                                                                            <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="child_item.file_mime == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || child_item.file_mime == 'application/vnd.ms-excel'"/> 
                                                                        </a>
                                                                    </center>
                                                                </CCol>
                                                                <CCol lg="9">
                                                                    <label :title="child_item.name + '.' + child_item.file.split('.')[1]"> {{child_item.name.length > 30 ? child_item.name.substring(0, 30) + '...' +  child_item.file.split('.')[1] : child_item.name + '.' + child_item.file.split('.')[1]}} </label>
                                                                </CCol>
                                                                <CCol lg="2">
                                                                    <center>
                                                                        <CButton 
                                                                            color="danger" 
                                                                            shape="pill" 
                                                                            :disabled="removing_internal_attachment ? (child_item.id == removing_internal_attachment_id ? true : false) : false"
                                                                            size="sm"
                                                                            @click="removeInternalAttachment(item, index, child_item, child_item_index)"
                                                                        >
                                                                            <font-awesome-icon 
                                                                                :icon="removing_internal_attachment ? (child_item.id == removing_internal_attachment_id ? 'circle-notch' : (item.status == 'old' ? 'trash' : 'times')) : (item.status == 'old' ? 'trash' : 'times')" 
                                                                                :pulse="removing_internal_attachment ? (child_item.id == removing_internal_attachment_id ? true : false) : false"
                                                                            />
                                                                        </CButton>
                                                                    </center>
                                                                </CCol>
                                                            </CRow>
                                                        </CCardBody>
                                                    </CCard>
                                                </CCollapse>
                                            </template>
                                        </CDataTable> -->
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="comments"/>
                                    Document Remarks
                                </template>
                                
                                <CRow>
                                    <CCol lg="12">
                                       <CButton 
                                            v-if="!data_loading 
                                                    && d_remarks.filter(x => x.user_id == $store.getters['getUserDetails'].id).length == parseInt('0')
                                                        && $store.getters['can']('routing-su-add-remarks')"
                                            class="float-left"
                                            @click="addRemarks()"
                                            color="success"
                                       >
                                           <font-awesome-icon icon="comment"/> Add Remarks
                                       </CButton>
                                       <CButton 
                                            v-if="d_remarks.filter(x => !x.id).length > parseInt('0')"
                                            class="float-right"
                                            color="info"
                                            @click="saveRemarks('stand-alone')"
                                       >
                                           <font-awesome-icon icon="save"/> Save Remarks
                                       </CButton>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            hover
                                            border
                                            :key="d_remarks_key"
                                            :items="d_remarks"
                                            :fields="remarks_field"
                                            sorter
                                            size="sm"
                                            :loading="data_loading"
                                        >
                                            <template slot="no-items-view" v-if="data_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            <template #action="{item, index}">
                                                <td>
                                                    <CButton 
                                                        v-if="item.user_id == $store.getters['getUserDetails'].id && item.id"
                                                        color="success" 
                                                        shape="pill" 
                                                        size="sm"
                                                        :disabled="saving_remarks ? (item.id == saving_remarks_id ? true : false) : false"
                                                        @click="saveRemarks('by-row', item, index)"
                                                    >
                                                    <font-awesome-icon 
                                                            :icon="(saving_remarks ? (item.id == saving_remarks_id ? 'circle-notch' : 'check') : 'check')" 
                                                            :pulse="saving_remarks ? (item.id == saving_remarks_id ? true : false) : false"
                                                        />
                                                    </CButton> &nbsp;
                                                    <CButton 
                                                        color="danger" 
                                                        shape="pill" 
                                                        size="sm"
                                                        :disabled="removing_remarks ? (item.id == removing_remarks_id ? true : false) : false"
                                                        @click="removeRemarks(item, index)"
                                                    >
                                                    <font-awesome-icon 
                                                            :icon="(removing_remarks ? (item.id == removing_remarks_id ? 'circle-notch' : (item.id ? 'trash': 'times')) : (item.id ? 'trash': 'times'))" 
                                                            :pulse="removing_remarks ? (item.id == removing_remarks_id ? true : false) : false"
                                                        />
                                                    </CButton>
                                                </td>
                                            </template>
                                            <template #remarks="{item}"> 
                                                <td :title="item.remarks">
                                                    <template v-if="item.id">
                                                        <template v-if="item.user_id != $store.getters['getUserDetails'].id">
                                                            {{ item.remarks.length > 100 ? item.remarks.substring(0, 100) + '...' : item.remarks}} 
                                                        </template>
                                                        <template v-else>
                                                            <CTextarea
                                                                class="grp-text-area"
                                                                placeholder="Remarks.."
                                                                autocomplete="off"  
                                                                v-tocapitalize
                                                                v-model="item.remarks"
                                                            /> 
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <CTextarea
                                                            class="grp-text-area"
                                                            placeholder="Remarks.."
                                                            autocomplete="off"  
                                                            v-tocapitalize
                                                            v-model="item.remarks"
                                                        /> 
                                                    </template>
                                                </td>
                                            </template>
                                            <template #remarks_by="{item}"> 
                                                <td>
                                                    {{ item.user.first_name }} 
                                                </td>
                                            </template>
                                            <template #date="{item}"> 
                                                <td>
                                                    <template v-if="item.id">
                                                        <small>{{ changeDateFormatV2(item.created_at) }}</small>
                                                    </template>
                                                    <template v-else>
                                                        <small>
                                                            <CBadge color="success">Recently Added</CBadge>
                                                        </small>
                                                    </template>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="share-square"/>
                                    Document Sharing
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                       <CButton 
                                            v-if="!data_loading && $store.getters['can']('routing-su-add-shared')"
                                            class="float-left"
                                            @click="addUser()"
                                            color="success"
                                       >
                                           <font-awesome-icon icon="user"/> Add User(s)
                                       </CButton>
                                       <a-popover title="NOTE:" placement="topRight">
                                            <template slot="content" >
                                            <p>If the user you wish to share this routing with does not exist in
                                                <br>the list, this user is either a signatory, document creator, or already 
                                                <br>on the list.</p>
                                            </template>
                                                <font-awesome-icon class="float-right" style="color: #4c8baf;" icon="info-circle" size="lg"/>
                                        </a-popover>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="d_users_shared"
                                            :fields="users_shared_routing_fields"
                                            border
                                            hover
                                            sorter
                                            :loading="data_loading"
                                        >
                                            <template slot="no-items-view" v-if="data_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            <template #name="{item}"> 
                                                <td>
                                                    {{ item.shared_to_user.first_name }} {{ item.shared_to_user.last_name }}
                                                </td>
                                            </template>
                                            <template #added_by="{item}"> 
                                                <td>
                                                    {{ item.source == 'User' ? item.shared_by_user.first_name : 'Default Added' }}
                                                </td>
                                            </template>
                                            <template #action="{item, index}"> 
                                                <td>
                                                    <template v-if="item.source == 'User'">
                                                        <CButton 
                                                                color="danger" 
                                                                shape="pill" 
                                                                size="sm"
                                                                :disabled="removing_shared ? (item.id == removing_shared_id ? true : false) : false"
                                                                @click="removeShared(item, index)"
                                                            >
                                                        <font-awesome-icon 
                                                                :icon="(removing_shared ? (item.id == removing_shared_id ? 'circle-notch' : (item.id ? 'trash': 'times')) : (item.id ? 'trash': 'times'))" 
                                                                :pulse="removing_shared ? (item.id == removing_shared_id ? true : false) : false"
                                                            />
                                                        </CButton>
                                                    </template>
                                                    <template v-else>
                                                        ---
                                                    </template>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab :disabled="data_loading">
                                <template slot="title">
                                    <font-awesome-icon icon="bezier-curve"/>
                                    Document Timeline
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <a-timeline>
                                            <a-timeline-item
                                                v-for="(routing, parent_index) in d_timeline.slice().reverse()" 
                                                v-bind:key="parent_index"
                                                :color="getColorStatus(routing.status)">

                                                <template v-if="routing.status == 't-past'">
                                                    {{routing.date}} <br>
                                                    {{routing.message}} <br><br>
                                                    <CRow>
                                                        <CCol lg="6" v-if="routing.requirements.length > 0">
                                                            <span>Signature Requirements<br></span>
                                                            <span v-for="(requirement, past_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{past_index+1}}. {{requirement.requirement}} <br>
                                                            </span>
                                                        </CCol>
                                                        <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                            <span >Send Back Remarks<br></span>
                                                            <span v-for="(remark, past_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                {{past_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                            </span>
                                                        </CCol>
                                                    </CRow>
                                                </template>

                                                <template v-if="routing.status == 't-cancelled' || routing.status == 't-cancelled-routing'">
                                                    <span v-if="routing.date" style="color: #e25f5f">{{routing.date}} <br></span>
                                                    <span style="color: #e25f5f" v-if="routing.status == 't-cancelled'">{{routing.message}}</span> <br v-if="routing.status == 't-cancelled'">
                                                    <span style="color: #e25f5f" v-if="routing.status == 't-cancelled-routing'"><strong>{{routing.message}}</strong></span> <br v-if="routing.status == 't-cancelled-routing'">
                                                    <i v-if="routing.remarks" style="color: #e25f5f">Reason/Remarks: {{routing.remarks}}<br><br></i> 
                                                    <span style="color: #e25f5f" v-if="routing.requirements.length > 0">Signature Requirements <br></span>
                                                    <span style="color: #e25f5f" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                        {{future_index+1}}. {{requirement.requirement}} <br>
                                                    </span>
                                                </template>

                                                <template v-if="routing.status == 't-present'">
                                                    <span v-if="routing.date" style="color: #4caf50">{{routing.date}} <br></span>
                                                    <span style="color: #4caf50"><strong>{{routing.message}}</strong></span> <br><br>
                                                    <CRow>
                                                        <CCol lg="6"  v-if="routing.requirements.length > 0">
                                                            <span style="color: #4caf50">Signature Requirements<br></span>
                                                            <span style="color: #4caf50" v-for="(requirement, present_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{present_index+1}}. {{requirement.requirement}} <br>
                                                            </span>
                                                        </CCol>
                                                        <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                            <span style="color: #4caf50" >Send Back Remarks<br></span>
                                                            <span style="color: #4caf50" v-for="(remark, past_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                {{past_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                            </span>
                                                        </CCol>
                                                    </CRow>
                                                </template>

                                                <template v-if="routing.status == 't-future'">
                                                    <span v-if="routing.date">{{routing.date}} <br></span>
                                                    <span style="color: #a39e9e">{{routing.message}}</span><br><br>
                                                    <CRow>
                                                        <CCol lg="6" v-if="routing.requirements.length > 0">
                                                            <span style="color: #a39e9e" >Signature Requirements<br></span>
                                                            <span style="color: #a39e9e" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{future_index+1}}. {{requirement.requirement}} <br>
                                                            </span>
                                                        </CCol>
                                                        <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                            <span style="color: #a39e9e" >Send Back Remarks<br></span>
                                                            <span style="color: #a39e9e" v-for="(remark, future_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                {{future_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                            </span>
                                                        </CCol>
                                                    </CRow>
                                                </template>
                                            </a-timeline-item>
                                        </a-timeline>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            :show.sync="routing_reference_number_modal"
            color="primary"
            size="lg"
            >
            <template #header>
                <h6> <font-awesome-icon icon="list"/> Select Routing Reference Number </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="routing_reference_number_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">
                            <CInput 
                                type="text" 
                                placeholder="Search" 
                                autocomplete="off" 
                                v-model="rrnm_search"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :key="rrnm_key"
                                :items="rrnm_list_items"
                                :fields="rrnm_fields"
                                border
                                sorter
                                @row-clicked="rrnm_selected_row"
                                pagination
                            >
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
        </CModal>
        <CModal 
            color="dark" 
            :show.sync="change_of_signatory_modal" 
            centered
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5><font-awesome-icon icon="user-slash" /> Change Signatory</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="change_of_signatory_modal = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        Selected signatory: <i><h5>{{change_signatory.selected}}</h5></i>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="12">
                        <label>Replace selected signatory to:</label>
                        <v-select
                            label="signatory_name"
                            :options="available_signatory"
                            v-model="change_signatory.new_signatory"
                            :class="!change_signatory.new_signatory ? 'has-error' : 'has-success'"
                        >
                        <template #option = "{signatory_name, signatory_purpose, signatory_requirements}">
                            <h6 style="margin: 0">{{ signatory_name }}</h6>
                           <small>Purpose : {{ signatory_purpose}}</small> <br>
                            <small>Requirements : {{ signatory_requirements.length}}</small>
                        </template>
                        </v-select>
                    </CCol>
                </CRow>
                <template v-if="change_signatory.new_signatory && change_signatory.new_signatory.signatory_requirements.length > 0">
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <span style="color: red;">Please select requirement(s)*</span>
                        </CCol>
                        <br><br>
                        <template>
                            <span v-for="(requirement, index) in change_signatory.new_signatory.signatory_requirements" v-bind:key="index">
                                <CCol lg="6" >
                                    <p-check 
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        :value="requirement.id"
                                        v-model="change_signatory.selected_requirements">
                                            <font-awesome-icon class="icon" icon="check"/>
                                            {{requirement.requirement}}
                                    </p-check> 
                                </CCol>
                            </span>
                        </template>
                    </CRow>
                </template>
                <br>
                <CRow>
                    <CCol lg="12">
                        <CTextarea
                            label="Reason"
                            placeholder="Content..."
                            autocomplete="off"  
                            rows="3"
                            :class="!change_signatory.reason ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area'"
                            v-tocapitalize
                            v-model="change_signatory.reason"
                        />
                    </CCol>
                </CRow> 
            </CCardBody>
            </template>
            <template #footer>
                <CRow>
                    <CButton
                        size="sm"
                        :title="!change_signatory.new_signatory || !change_signatory.reason || (change_signatory.new_signatory.signatory_requirements.length > 0 && change_signatory.selected_requirements.length <= 0) ? 'Kindly fill-up required field(s).' : ''"
                        color="primary" 
                        :disabled="!change_signatory.new_signatory || !change_signatory.reason || (change_signatory.new_signatory.signatory_requirements.length > 0 && change_signatory.selected_requirements.length <= 0)"
                        @click="!change_signatory.new_signatory || !change_signatory.reason || (change_signatory.new_signatory.signatory_requirements.length > 0 && change_signatory.selected_requirements.length <= 0) ? '' : submitChangeSignatory()"
                    >
                        <font-awesome-icon icon="user-slash" /> Submit Changed Signatory
                    </CButton>
                </CRow>

            </template>
        </CModal>
        <CModal 
            color="success" 
            :show.sync="add_signatory_modal" 
            centered
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5><font-awesome-icon icon="user-plus" /> Add Signatory</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="add_signatory_modal = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        <label>Select New Signatory</label>
                        <v-select
                            label="signatory_name"
                            :options="available_signatory"
                            v-model="add_signatory.new_signatory"
                            :class="!add_signatory.new_signatory ? 'has-error' : 'has-success'"
                        >
                        <template #option = "{signatory_name, signatory_purpose, signatory_requirements}">
                            <h6 style="margin: 0">{{ signatory_name }}</h6>
                            <small>Purpose : {{ signatory_purpose}}</small> <br>
                            <small>Requirements : {{ signatory_requirements.length}}</small>
                        </template>
                        </v-select>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="4">
                        <label>Order</label>
                        <CInput 
                            type="text" 
                            maxlength="2"
                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'') "
                            placeholder="Order (1)" 
                            autocomplete="off"  
                            v-model="add_signatory.order"
                            :class="!add_signatory.order ? 'has-inp-error' : 'has-inp-success'"
                        />
                    </CCol>
                </CRow>
                <template v-if="add_signatory.new_signatory && add_signatory.new_signatory.signatory_requirements.length > 0">
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <span style="color: red;">Please select requirement(s)*</span>
                        </CCol>
                        <br><br>
                        <template>
                            <span v-for="(requirement, index) in add_signatory.new_signatory.signatory_requirements" v-bind:key="index">
                                <CCol lg="6">
                                    <p-check 
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        :value="requirement.id"
                                        v-model="add_signatory.selected_requirements">
                                            <font-awesome-icon class="icon" icon="check"/>
                                            {{requirement.requirement}}
                                    </p-check> 
                                </CCol>
                            </span>
                        </template>
                    </CRow>
                </template>
            </CCardBody>
            </template>
            <template #footer>
                <CRow>
                    <!-- () -->
                    <!-- @click="add_signatory.new_signatory ?  : ''" -->

                    <CButton
                        size="sm"
                        :title="!add_signatory.new_signatory || (add_signatory.new_signatory.signatory_requirements.length > 0 && add_signatory.selected_requirements.length <= 0) || !add_signatory.order? 'Complete necessary fields' : ''"
                        color="primary" 
                        :disabled="!add_signatory.new_signatory || (add_signatory.new_signatory.signatory_requirements.length > 0 && add_signatory.selected_requirements.length <= 0) || !add_signatory.order"
                        @click="add_signatory.new_signatory && add_signatory.order? 
                                        add_signatory.new_signatory.signatory_requirements.length > 0 ? 
                                            add_signatory.selected_requirements.length > 0 ? submitAddedSignatory() 
                                                : ''
                                        : submitAddedSignatory()
                                    : ''
                                "
                    >
                        <font-awesome-icon icon="user-plus" /> Submit Added Signatory
                    </CButton>
                </CRow>

            </template>
        </CModal>
        <CModal 
            color="warning" 
            :show.sync="return_to_signatory_modal" 
            :closeOnBackdrop="false"
            centered
        >
            <template #header>
                <h5><font-awesome-icon icon="backspace" /> Return To Signatory</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="return_to_signatory_modal = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        <label>Select Signatory</label>
                        <v-select
                            label="name"
                            :options="signed_signatories"
                            v-model="return_document.user"
                        >
                        </v-select>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol lg="12">
                        <CTextarea
                            label="Remarks"
                            placeholder="Content..."
                            autocomplete="off"  
                            rows="3"
                            v-tocapitalize
                            v-model="return_document.reason"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
            </template>
            <template #footer>
                <CRow>
                    <CButton
                        size="sm"
                        color="info" 
                        :disabled="!return_document.user || !return_document.reason"
                        @click="submitReturnToSignatory()"
                    >
                        <font-awesome-icon icon="backspace" /> Return
                    </CButton>
                </CRow>

            </template>
        </CModal>
        <UsersSelectableModal ref="usersSelectableModal" @selectedUser="userSelected($event)"/>
    </div>
</template>

<script>
import UsersSelectableModal from '../../modals/UsersSelectableModal'
export default {
    name: 'RoutingSUManage',
    components: {
        UsersSelectableModal,
    },
    data() {
        return {
            lst_pge_srch : {
                reference_number: null,
            },
            data_loading: true,
            active_tab: 0,
            document_categories: [],


            routing_reference_number_modal: false,
            is_getting_rrn: false,
            is_getting_file_to_add_internally: false,
            rrnm_key: 0, // rrnm stands for routing_reference_number_modal
            rrnm_search: '',
            rrnm_list: [],
            rrnm_fields: [
                { key: 'id'},
                { key: 'reference_number' },
            ],

            change_of_signatory_modal: false,
            available_signatory:[],
            change_signatory: {
                selected: null,
                selected_row_id: null,
                new_signatory:null,
                selected_requirements:[],
                reason: null,
            },
            changing_signatory: false,
            changing_signatory_id: null,

            returning_to_signatory: false,
            returning_to_draft: false,

            return_to_signatory_modal: false,
            return_document: {
                user: null,
                reason:null,
            },

            
            add_signatory_modal: false,
            adding_signatory: false,
            add_signatory:{
                new_signatory:null,
                selected_requirements:[],
                order: null
            },
        
            // -----------------------TAB 0--------------------------
            edit_info: false,
            d_informations: {
                status: null,
                reference_number: '',
                description: '',
                document_file: []
            },
            d_informations_backup: {
                status: null,
                reference_number: '',
                description: '',
                document_file: []
            },

            // -----------------------TAB 1--------------------------
            edit_signatory: false,
            d_template_and_signatories: {
                template_id: null,
                template_name: '',
                signatories: [],
            },
            d_template_and_signatories_backup: {
                template_id: null,
                template_name: '',
                signatories: [],
            },

            // -----------------------TAB 2--------------------------
            d_external_attachments: [],
            d_external_attachments_backup: [],
            removing_external_attachment_id: null,
            removing_external_attachment: false,
            d_external_attachments_key: 0,
            
            // -----------------------TAB 3-------------------------
            d_internal_attachments: [],
            d_internal_attachments_backup: [],
            removing_internal_attachment_id: null,
            removing_internal_attachment: false,
            d_internal_attachments_key: 0,
            chosen: {
                cat: null, // cat stands for category
                rrn: null, // rrn stands for routing_refererence_number
                rrn_id: null,
                rmks: '' // rmks stands for remarks
            },

            // -----------------------TAB 4--------------------------
            d_remarks: [],
            d_remarks_key:0,
            d_remarks_backup: [],
            removing_remarks: false,
            removing_remarks_id: null,
            saving_remarks: false,
            saving_remarks_id: null,

            // -----------------------TAB 5--------------------------
            d_users_shared: [],
            d_shared_users_signatory_and_creator_id: [],
            d_d_users_shared_backup: [],
            removing_shared: false,
            removing_shared_id: null,

            // -----------------------TAB 6--------------------------
            d_timeline: [],

            // -----------------------SIGNATORY SWAPPING--------------------------
            is_swapping: false,
            display_swap_button : true
        }
    },
    created() {
        if(this.active_tab == 0) {
            this.getDocumentInformations();
        }

        this.searchParams = this.$route.query.searchParams;
        this.selected_toggled = this.$route.query.selected_toggled;
        if(this.searchParams && this.selected_toggled) {
            this.$router.replace({
                query : {
                    id : this.$route.query.id,
                    template_name : this.$route.query.template_name
                },
            });
        }
    },
    computed: {
        rrnm_list_items: function () {
            return this.rrnm_list.filter((e) => e.reference_number.toLowerCase().indexOf(this.rrnm_search.toLowerCase()) > -1);
        },
        signatories_field: function (){
            let custom_fields= [
                {key: 'line_id', _classes: 'd-none'},
                {key: 'user_id', _classes: 'd-none'},
                {key: 'name'},
                {key: 'purpose'},
                {key: 'order',},
                {key: 'required_signature', label: 'Signature'},
                {key: 'signed', label: 'Status'},
                {key: 'requirements'},
            ]
            if((this.$store.getters['can']('routing-su-remove-signatory') || this.$store.getters['can']('routing-su-change-signatory')) 
                        && [1,2].includes(parseInt(this.d_informations_backup.status))) {
                custom_fields.push({ key: 'action', sorter: false, _classes: 'th-fixed-width'})
            }
            return custom_fields;
        },
        remarks_field: function () {
            let custom_fields= [
                { key: 'remarks'},
                { key: 'remarks_by'},
                { key: 'date'},
            ]
            if(this.$store.getters['can']('routing-su-remove-remarks')) {
                custom_fields.push({ key: 'action' })
            }
            return custom_fields;
        },
        external_attachments_field: function () {
            let custom_fields= [
                {key: 'filetype', label: ''},
                {key: 'filename'},
                {key: 'filesize'},
                {key: 'remarks'},
                {key: 'attachment'},
                {key: 'date_uploaded'},
                {key: 'uploaded_by'},
            ]
            if(this.$store.getters['can']('routing-su-remove-external-attachment')) {
                custom_fields.push({ key: 'action' })
            }
            return custom_fields;
        },
        users_shared_routing_fields: function (){
            let custom_fields = [
                { key: 'name', label: 'Routing shared to'},
                { key: 'added_by'},
            ]
            
            if(this.$store.getters['can']('routing-su-remove-shared')) {
                custom_fields.push({ key: 'action' })
            }
            return custom_fields;
        },
        internal_attachments_fields: function (){
            let custom_fields = [
                {key: 'filetype', label: '', _classes: 'td-fxd-wdth'},
                {key: 'filename'},
                {key: 'filesize'},
                {key: 'remarks'},
                {key: 'checklist', label: 'Attachment' },
                {key: 'date_uploaded'},
                {key: 'uploaded_by'},   
            ]
            if(this.$store.getters['can']('routing-su-remove-internal-attachment')) {
                custom_fields.push({key: 'action', _classes: 'td-actn-fxd-wdth'})
            }
            return custom_fields;
        }, 
        last_signatory_id: function () {
            if(this.d_template_and_signatories.signatories) {
                let signatories = this.d_template_and_signatories.signatories.filter((signatory) => signatory.signature_id == null && signatory.cancelled_at == null);
                if(signatories.length > 1) {
                    return 0;
                }
                return signatories.at(-1).id;
            }
        },
        first_signatory_line_id: function () {
            return this.d_template_and_signatories.signatories.filter(x => x.order == "1")[0].id
        },
        signed_signatories: function () {
            let signatories = [];
            for (let i = 0; i < this.d_template_and_signatories.signatories.length; i++) {
                const element = this.d_template_and_signatories.signatories[i];
                if(element.signed_at) {
                    let row = {
                        name: `${element.document_template_signature.user.first_name} ${element.document_template_signature.user.last_name}`,
                        document_routing_header_id: element.document_routing_header_id,
                        document_routing_line_id: element.id,
                    }
    
                    if(!signatories.some(s => s.document_routing_line_id === row.document_routing_line_id)) {
                        signatories.push(row)
                    }
                }
            }
            return signatories;
        },
        has_signed_signatory: function () {
            let signed_signatory = this.d_template_and_signatories.signatories.filter(x => x.signed_at);
            if(signed_signatory.length > 0) { // has signed
                return true;
            } else { // no signed
                return false;
            }
        },
    },
    methods: {
        activateSwap: function(){
            this.is_swapping = true;

            const table = document.querySelector('.signatories_table');
            const tbody = table.querySelector('tbody');
            const rows = tbody.rows;
            let draggedRow;

            if (table) {
                // Add drag start event listener
                rows.forEach(row => {
                    if(!row.classList.contains("p-0") && !row.classList.contains("table-success") && !row.classList.contains("table-danger") && !row.classList.contains("not_required")){

                    row.setAttribute('draggable', true)
                        row.addEventListener('dragstart', function (e) {
                            draggedRow = this;
                            this.classList.add('dragging');
                        });
                    }

                    // Add dragend event listener to each row
                    row.addEventListener('dragend', function (e) {
                        // Remove 'dragging' class from all rows
                        rows.forEach(r => {
                            r.classList.remove('dragging') 
                            r.classList.remove('drag-over')
                            r.classList.remove('drop-location')
                        });
                    });
                });

                // Add dragover event listener to the table
                tbody.addEventListener('dragover', function (e) {
                    e.preventDefault();
                    const targetRow = getRow(e.target);
                    if(!targetRow.classList.contains("not_required") && !targetRow.classList.contains("table-success") && !targetRow.classList.contains("table-danger")) {
                        if (targetRow && draggedRow !== targetRow) {
                            targetRow.classList.add('drag-over');
                            targetRow.classList.add('drop-location');
                        }
                    }
                });

                // Add dragleave event listener to the table
                tbody.addEventListener('dragleave', function (e) {
                    e.preventDefault();
                    const targetRow = getRow(e.target);
                    if(!targetRow.classList.contains("not_required") && !targetRow.classList.contains("table-success") && !targetRow.classList.contains("table-danger")) {
                        if (targetRow && draggedRow !== targetRow) {
                            targetRow.classList.remove('drag-over');
                            targetRow.classList.remove('drop-location');
                        }
                    }
                });

                // Add drop event listener
                tbody.addEventListener('drop', function (e) {
                    e.preventDefault();
                    const targetRow = getRow(e.target);
                    if(!targetRow.classList.contains("not_required") && !targetRow.classList.contains("table-success") && !targetRow.classList.contains("table-danger")) {
                        if (targetRow && draggedRow !== targetRow) {
                            try {
                                this.swapSignatory(draggedRow,targetRow);
                            } catch (error) {
                                console.error("Failed to insert row:", error);
                            }
                            
                        }
                    } 
                }.bind(this));


                function getRow(target) {
                    while (target.nodeName !== 'TR' && target !== table) {
                        target = target.parentNode;
                    }
                    if (target.nodeName === 'TR') {
                        return target;
                    } else {
                        return null;
                    }
                }

            } else {
                console.log('Table not found');
            }

        },
        cancelSwapSignatory: function() {
            const table = document.querySelector('.signatories_table');
            const tbody = table.querySelector('tbody');
            const rows = tbody.rows;
            rows.forEach(row => {
                row.setAttribute('draggable', false)
            });
            this.is_swapping = false;
        },
        swapSignatory: function(draggedRow,targetRow) {
            if (draggedRow && targetRow) {
                const draggedRowLineID = draggedRow.querySelector('td:nth-child(1)').textContent.trim()
                const draggedRowUserID = draggedRow.querySelector('td:nth-child(2)').textContent.trim()
                const draggedRowSignatoryName = draggedRow.querySelector('td:nth-child(3)').textContent.trim()
                const draggedRowOrderNumber = draggedRow.querySelector('td:nth-child(5)').textContent.trim()
                const targetRowLineID = targetRow.querySelector('td:nth-child(1)').textContent.trim()
                const targetRowUserID = targetRow.querySelector('td:nth-child(2)').textContent.trim()
                const targetRowSignatoryName = targetRow.querySelector('td:nth-child(3)').textContent.trim()
                const targetRowOrderNumber = targetRow.querySelector('td:nth-child(5)').textContent.trim()

                let message = `You are trying to swap <b>${draggedRowSignatoryName} </b> and <b> ${targetRowSignatoryName }</b> as signatory in this routing.`

                this.$swal({
                    icon: "question",
                    title: "Are you sure?",
                    html: message,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    preConfirm: () => { 
                        this.$Progress.start()
                        let data = {
                                routing_id: this.$route.query.id,
                                dragged_line_id: this.paramEncoder(draggedRowLineID),
                                dragged_order_number: draggedRowOrderNumber,
                                dragged_user_name: draggedRowSignatoryName,
                                target_line_id: this.paramEncoder(targetRowLineID),
                                target_order_number:targetRowOrderNumber,
                                target_user_name: targetRowSignatoryName,
                        }
                        return axios.post('/routing-su/document-template-and-signatories/swap', data, {validateStatus: () => true})
                        .then(response => {
                            if(response.status == 200) {
                                this.getDocumentTemplateAndSignatories()
                                let resp = `<b>${draggedRowSignatoryName} </b> and <b> ${targetRowSignatoryName }</b> have been swapped successfully.`;
                                this.$swal({
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    icon: 'success',
                                    title: resp,
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                })

                                this.is_swapping = false
                                this.$Progress.finish()
                                const table = document.querySelector('.signatories_table');
                                const tbody = table.querySelector('tbody');
                                const rows = tbody.rows;
                                targetRow.classList.remove('drag-over');
                                targetRow.classList.remove('drop-location');
                                rows.forEach(row => {
                                    row.setAttribute('draggable', false)
                                });
                            }

                        }) 
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
            }
            
        },
        goBack: function () {
            this.$router.go(-1)
        },  
        changeTab: function (item) {
            this.active_tab = item
        },
        getDocumentInformations: function () {
            this.$Progress.start()
            this.data_loading = true;
            axios.get(`/routing-su/document-informations/${this.$route.query.id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data;
                    let file = {
                        name: `${data.reference_number}.pdf`,
                        type: 'application/pdf',
                        custom_size: 4000000,
                        size: data.file_size,
                        url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${data.id}/${this.encodedAndCleanFileName(data.reference_number)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                    }

                    this.d_informations.document_file.push(file);
                    this.d_informations.status = data.status
                    this.d_informations.reference_number = data.reference_number
                    this.d_informations.description = data.description
                    this.d_informations_backup = JSON.parse(JSON.stringify(this.d_informations));
                    this.$Progress.finish()
                    this.data_loading = false;
                }
            })
        },
        getDocumentTemplateAndSignatories: function () {
            this.startLoading()
            this.d_template_and_signatories.signatories = [];
            this.d_template_and_signatories_backup.signatories = [];
            let counter = 0
            let id = this.$route.query.id;
            axios.get(`/routing-su/document-template-and-signatories/${id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data;
                    this.d_template_and_signatories.template_id = data.template.id
                    this.d_template_and_signatories.template_name = data.template.name
                    data.signatories.forEach(function (element) {       
                        if(element.cancelled_at) {
                            element._classes = '';
                        } else if (element.signed_at) {
                            element._classes = ''
                        } else if(element.order == 1 ) {
                            element._classes = 'not_required'
                        } else {
                            counter++
                        }
                    });
                    if(counter > 1) {
                        this.display_swap_button = true
                    } else {
                        this.display_swap_button = false
                    }
                    this.d_template_and_signatories.signatories = data.signatories
                    this.d_template_and_signatories_backup = JSON.parse(JSON.stringify(this.d_template_and_signatories));
                    this.endLoading()
                }
                this.data_loading = false;
            })
        },
        getDocumentExternalAttachments: function () {
            this.startLoading()
            this.d_external_attachments = []
            this.d_external_attachments_backup = []
            this.d_external_attachments_key++;
            let id = this.$route.query.id;
            axios.get(`/routing-su/document-external-attachments/${id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let arr = response.data.data;
                    const data = arr.map(el => ({...el, 
                            url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${el.id}/${this.paramDecoder(this.$route.query.id)}/${this.encodedAndCleanFileName(el.name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                        })
                    )
                    this.d_external_attachments = data
                    this.d_external_attachments_backup = JSON.parse(JSON.stringify(this.d_external_attachments));
                    this.endLoading()
                }
                this.data_loading = false;
            })
        },
        getDocumentInternalAttachments: function () {
            this.startLoading()
            this.d_internal_attachments = []
            this.d_internal_attachments_backup = []
            this.d_internal_attachments_key++;
            axios.get(`/routing-su/document-internal-attachments/${this.$route.query.id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let attachment_id = 0;
                    for (let idx = 0; idx < response.data.data.length; idx++) {
                        // this.internal_files_counter++;
                        const element = response.data.data[idx];
                        let file = {
                            id: element.id,
                            parent: false, 
                            main: element.previous_document_routing_header.reference_number == element.name ? true : false,
                            reference_number: element.previous_document_routing_header.reference_number, 
                            attachment_id: element.attachment_id,
                            filename: element.name,
                            file: element.file,
                            filetype: element.file_mime,
                            fileext: element.file_ext,
                            filesize: this.formatBytes(element.file_size),
                            remarks: element.remarks,
                            checklist: element.document_template_category_set_attachment_checklist,
                            checklist_old_id: element.dtcac_id,
                            _classes: '',
                            date_uploaded: this.changeDateFormat(element.created_at),
                            uploaded_by: element.user.first_name,
                            uploaded: element.user_id,
                            url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${element.id}/${this.paramDecoder(this.$route.query.id)}/${this.encodedAndCleanFileName(element.name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                        }
                        if(attachment_id != parseInt(element.attachment_id)) {
                            let parent_file = {
                                id:null,
                                parent: true, 
                                main:false,
                                reference_number: element.previous_document_routing_header.reference_number, 
                                attachment_id: null,
                                filename: "", 
                                file:"",
                                filetype: "", 
                                filesize: "", 
                                fileext: "", 
                                remarks: "", 
                                checklist: null,
                                checklist_old_id: null,
                                _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`,
                                date_uploaded: "",
                                uploaded_by: "",
                                uploaded: element.user_id,
                                url: null,
                            }
                            this.d_internal_attachments.push(parent_file) // pushing parent file
                            this.d_internal_attachments.push(file) // pushing main file
                            attachment_id = element.attachment_id;
                        } else {
                            this.d_internal_attachments.push(file) // pushing supporting file
                        }
                    }


















                    // let tmp = response.data.data.map(({previous_document_routing_header}) => (previous_document_routing_header));
                    // let jsonObject = tmp.map(JSON.stringify);
                    // let uniqueSet = new Set(jsonObject);
                    // let parent_routing = Array.from(uniqueSet).map(JSON.parse);
                    // let children =  response.data.data.map(el => ({...el, 
                    //                         url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${el.id}/${this.$route.query.id}/${this.encodedAndCleanFileName(el.name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                    //                     })
                    //                 )

                    // const data = parent_routing.map(el => ({...el, 
                    //                     status: 'old',
                    //                     remarks: children.filter((o) => parseInt(el.id) == parseInt(o.attachment_id))[0].remarks,
                    //                     uploaded_by: children.filter((o) => parseInt(el.id) == parseInt(o.attachment_id))[0].user,
                    //                     date_uploaded: children.filter((o) => parseInt(el.id) == parseInt(o.attachment_id))[0].created_at,
                    //                     attachments: children.filter((o) => parseInt(el.id) == parseInt(o.attachment_id))
                    //                 })
                    //             )
                    // this.d_internal_attachments = data;
                    // this.d_internal_attachments_backup = JSON.parse(JSON.stringify(this.d_internal_attachments));
                    this.endLoading()
                }
                this.data_loading = false;
            })
        },
        getDocumentRemarks: function () {
            this.startLoading()
            this.d_remarks = []
            this.d_remarks_backup = []

            axios.get(`/routing-su/document-remarks/${this.$route.query.id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data;
                    this.d_remarks = data
                    this.d_remarks_backup = JSON.parse(JSON.stringify(this.d_remarks));
                    this.endLoading();   
                }
                this.data_loading = false;
            })
        },
        getDocumentSharedUsers: function () {
            this.startLoading()
            this.d_users_shared = []
            this.d_users_shared_backup = []
            axios.get(`/routing-su/document-shared-users/${this.$route.query.id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data.user_shared;
                    this.d_users_shared = data;
                    this.d_users_shared_backup = JSON.parse(JSON.stringify(this.d_users_shared));

                    let signatory = response.data.data.signatory_shared;
                    let creator = response.data.data.creator_shared;

                    this.d_shared_users_signatory_and_creator_id = signatory.map(x => parseInt(x.document_template_signature.user_id))

                    this.d_shared_users_signatory_and_creator_id.push(creator.user_id);

                    this.endLoading();   
                }
                this.data_loading = false;
            })
        },
        getDocumentTimeLine: function () {
            this.$Progress.start()
            this.d_timeline = [];
            this.data_loading = true;
            axios.get(`/routing-su/documents-timeline/${this.$route.query.id}`, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let _created = {
                        date: `${this.changeDateFormat(response.data.data.created_at)}`,
                        message: `Document Created by ${response.data.data.user.first_name} ${response.data.data.user.last_name}`,
                        requirements: [],
                        send_back_remarks: [],
                    }
                    _created.status = !response.data.data.started_at ? 't-present': 't-past';

                    let _started = {
                        date: `${this.changeDateFormat(response.data.data.started_at)}`,
                        message: `Document Routing Started by ${response.data.data.user.first_name} ${response.data.data.user.last_name}`,
                        status: 't-past',
                        requirements: [],
                        send_back_remarks: [],
                    }

                    this.d_timeline.push(_created)
                    if(response.data.data.started_at) {
                        this.d_timeline.push(_started)
                    }
                    
                    let activity = response.data.data.document_routing_timeline_views

                    let signatory_counter=0;
                    if(activity.length > 0 ){ 
                        for (let i = 0; i < activity.length; i++) {
                            if(activity[i].signed_at) signatory_counter++;
                            let _row = {
                                message: `${activity[i].messages ? activity[i].messages : 'wala'}`,
                                requirements: activity[i].document_signature_requirements,
                                send_back_remarks: activity[i].document_routing_sendback_reasons,
                            }
                            if(response.data.data.status != 4) {
                                if(activity[i].status == 1) {
                                    _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                                    _row.status = 't-future'
                                } else if (activity[i].status == 2) {
                                    _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                                    _row.status = !response.data.data.started_at ? 't-future' : 't-present'
                                } else if (activity[i].status == 3) {
                                    _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                                    _row.status = 't-past'
                                } else if (activity[i].status == 4) {
                                    _row.date = `${this.changeDateFormat(activity[i].cancelled_at)}`,
                                    _row.remarks = activity[i].remarks
                                    _row.status = 't-cancelled'
                                }
                            } else {
                                _row.date = `${this.changeDateFormat(response.data.data.updated_at)}`,
                                _row.status = 't-past'
                            }
                            this.d_timeline.push(_row)
                        }
                    }

                    let _completion = {
                        date: '',
                        message: response.data.data.status != 4 ? 'Document Completed.' : 'Document Cancelled.',
                        status:  response.data.data.status == 4 ? 't-cancelled-routing' : response.data.data.status == 3 ? 't-present' : signatory_counter == activity.length ? 't-present' : 't-future',
                        requirements: [],
                        send_back_remarks: [],
                    }
                    if(signatory_counter == activity.length || response.data.data.status == 4 || response.data.data.status == 3) {
                        _completion.date = `${this.changeDateFormat(response.data.data.updated_at)}`
                    }
                    this.d_timeline.push(_completion)
                    
                    this.data_loading = false;
                    this.$Progress.finish()
                }
                this.data_loading = false;
            })
        },
        getDocumentCategory: function () {
            this.$Progress.start()
            axios.get('/drs/document-template-category/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.document_categories = response.data.data;
                    this.$Progress.finish()
                }
            })
        },
        removeSignatory: function (item, index) {
            let dr_id = this.$route.query.id;
            // let reference_number = this.$route.query.reference_number;
            let reference_number = this.d_informations_backup.reference_number;
            let template_name = this.$route.query.template_name;
            let signatory = this.d_template_and_signatories.signatories.filter(x => x.id == item.id)[0];
            let message = `You are trying to remove <b>${signatory.document_template_signature.user.first_name}</b> as signatory in this routing.
            <br>
            Kindly add remarks to continue.`
            if(this.last_signatory_id == item.id) {
                message = `<u>Removing</u> <b>${signatory.document_template_signature.user.first_name}</b> as last signatory will <i>mark this routing as COMPLETED.</i>
                <br>
                Kindly add remarks to continue.`
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: message,
                input: 'textarea',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Enter remarks to proceed",
                showLoaderOnConfirm: true,
                preConfirm: (remover_remarks) => { 
                    this.$Progress.start()
                    if(!remover_remarks) {
                        this.$swal.showValidationMessage('Reason cannot be empty.')
                        this.$Progress.fail()
                        return;
                    }

                    if(remover_remarks.length < 5 ) {
                        this.$swal.showValidationMessage('Reason must not be less than 5 Characters.')
                        this.$Progress.fail()
                        return;
                    }

                    let data = {
                        document_routing_header_id: this.$route.query.id,
                        document_routing_lines_id: this.paramEncoder(item.id),
                        remarks: remover_remarks
                    }
                    return axios.post(`/routing-su/document-template-and-signatories/remove`, data, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            let resp = `<b>${signatory.document_template_signature.user.first_name}</b> removed as signatory in the routing.`;
                            if(this.last_signatory_id == item.id) { 
                                this.$router.replace({query: {id: dr_id, reference_number: reference_number, status: 3, template_name: template_name}})
                                resp = `<b>${signatory.document_template_signature.user.first_name}</b> removed as signatory & mark the routing as COMPLETED.`
                            }

                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: resp,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            let now = new Date();
                            signatory.cancelled_at = now;
                            signatory._classes = 'table-danger';
                            this.getDocumentInformations();
                            this.$Progress.finish()

                        } else {
                            this.getDocumentTemplateAndSignatories();
                        }
                    }) 
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        changeSignatory: function (item, index) {
            this.$Progress.start()
            this.changing_signatory = true;
            this.changing_signatory_id = item.id;
            this.available_signatory = []
            this.change_signatory.selected =  item.document_template_signature.user.first_name + ' ' + item.document_template_signature.user.last_name
            this.change_signatory.selected_row_id = item.id
            let decode_data = decodeURIComponent(this.$route.query.id);
            let doc_head_id = atob(decode_data);
            let data = {
                document_routing_header_id: doc_head_id
            }
            axios.post('/drs/document-routing/change-signatory-list', data, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let signatory_list = this.d_template_and_signatories.signatories;
                    let exist_signatory = signatory_list.filter(x => x.signed_at == null && x._classes == null);
                    let uid_list = [];
                    for (let i = 0; i < response.data.data.length; i++) {
                        let _row = {
                            id: response.data.data[i].id,
                            user_id: response.data.data[i].user_id,
                            signatory_name: response.data.data[i].user.first_name + ' ' + response.data.data[i].user.last_name,
                            signatory_purpose : response.data.data[i].purpose,
                            signatory_requirements:response.data.data[i].document_signature_requirements,
                        }
                        exist_signatory.forEach(element => {
                            let uid = element.document_template_signature.user_id;
                            uid_list.push(uid);
                        });

                        if(!uid_list.includes(_row.user_id)){
                            this.available_signatory.push(_row);
                        }
                    }
                    this.change_of_signatory_modal = true;
                    this.$Progress.finish()
                } else {
                    this.changing_signatory = false;
                    this.changing_signatory_id = null;
                }
            })
        },
        signatoryProps (item) {
            return {
                name : item.signatory_name,
                purpose : item.signatory_purpose,
                requirements : item.signatory_requirements
            }
        },
        submitChangeSignatory: function () {
            let data = {
                document_routing_header_id: this.$route.query.id,
                pull_out_line_id: this.paramEncoder(this.change_signatory.selected_row_id),  
                document_template_signature_id: this.paramEncoder(this.change_signatory.new_signatory.id),
                user_id: this.paramEncoder(parseInt(this.change_signatory.new_signatory.user_id)),
                remarks: this.change_signatory.reason
                
            }
            if(this.change_signatory.selected_requirements.length > 0) {
                data.signature_requirement_ids = this.change_signatory.selected_requirements;
            }
            return this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to change <b>${this.change_signatory.selected}</b> and replace by <b>${this.change_signatory.new_signatory.signatory_name}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/routing-su/document-template-and-signatories/change', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `Signatories has been changed.`,
                            })
                            this.change_of_signatory_modal = false
                            this.$Progress.finish()
                            this.changing_signatory = false;
                            this.changing_signatory_id = null;
                            this.getDocumentTemplateAndSignatories();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        addSignatory: function () {
            this.adding_signatory = true;
            this.available_signatory = [];
            let decode_id = decodeURIComponent(this.$route.query.id);
            let header_id = atob(decode_id);
            this.$Progress.start()
            let data = {
                document_routing_header_id: header_id, 
                document_template_id: this.d_template_and_signatories.template_id
            }
            axios.post('/drs/document-routing/add-signatory-list', data, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let _row = {
                            id: response.data.data[i].id,
                            user_id: response.data.data[i].user_id,
                            signatory_name: response.data.data[i].user.first_name + ' ' + response.data.data[i].user.last_name,
                            signatory_purpose : response.data.data[i].purpose,
                            signatory_requirements:response.data.data[i].document_signature_requirements,
                        }
                        this.available_signatory.push(_row);
                    }
                    this.add_signatory_modal = true;
                }
                this.adding_signatory = false
            })
            this.$Progress.finish()
        },
        submitAddedSignatory: function (item, index) {
            let data = {
                document_routing_header_id: this.$route.query.id,
                document_template_signature_id: this.paramEncoder(this.add_signatory.new_signatory.id),
                order: this.add_signatory.order
            }
            
            if(this.add_signatory.selected_requirements.length > 0) {
                data.signature_requirement_ids = this.add_signatory.selected_requirements;
            }
            return this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to add <b>${this.add_signatory.new_signatory.signatory_name}</b> as a signatory number <b>${this.add_signatory.order}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post(`/routing-su/document-template-and-signatories/add`, data, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `New signatory has been added.`,
                            })
                            
                            this.add_signatory_modal = false;
                            this.adding_signatory = false
                            this.getDocumentTemplateAndSignatories();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        returnInformation: function () {
            this.d_informations = JSON.parse(JSON.stringify(this.d_informations_backup));
        },
        removeMainFile: function (file) {
            // let file_index = this.d_informations.document_file.indexOf(file);
            // if(file_index !== -1) this.d_informations.document_file.splice(file_index, 1);
            // if(this.d_informations.status != 1) {
            //     return this.$swal({
            //         toast: true,
            //         position: 'top-right',
            //         timer: 3000,
            //         icon: 'error',
            //         title: `Main file can only be change in draft status.`,
            //         timerProgressBar: true,
            //         showConfirmButton: false,
            //     }) 
            // }
            alert('Still on-going');
        },
        updateDocumentInformation: function () {
            if(JSON.stringify(this.d_informations) == JSON.stringify(this.d_informations_backup)) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `No changes has been made.`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }) 
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to update the document routing information`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let data = {
                        document_routing_header_id: this.$route.query.id,
                        reference_number: this.paramEncoder( this.d_informations.reference_number),
                        description: this.d_informations.description
                    }
                    this.$Progress.start()
                    return axios.post('/routing-su/document-informations/update', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `Document routing information has been updated.`,
                            })
                            this.$Progress.finish()
                            this.toggleEdit()
                            this.d_informations = this.clearDocumentInformations();
                            this.getDocumentInformations();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        removeExternalAttachment: function (item, index) {
            this.d_external_attachments_key++;
            if(!item.id) {
                let name = (item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name) + '.' + item.name.split('.').pop()
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'success',
                    title: `${name} has been removed!`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                this.d_external_attachments.splice(index, 1)
                return;
            }
            
            this.removing_external_attachment = true;
            this.removing_external_attachment_id = item.id;

            if(this.d_informations_backup.status != 3) {
                this.$Progress.start()
                this.removeExternalAttachmentAxiosFunc(item)
                .then(response => { 
                    this.removeExternalAttachmentAxiosFuncRes(item, index)
                })
            } else {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    html: `When this external attachment is removed, it will also be deleted from any succeeding routing that was made. This action cannot be undone. <br>Do you still want to continue?`,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return this.removeExternalAttachmentAxiosFunc(item)
                        .then(response => { 
                            this.removeExternalAttachmentAxiosFuncRes(item, index)
                        })
                    }
                }).then (resp => {
                    this.removing_external_attachment = false;
                    this.removing_external_attachment_id = null;
                })
            }
        },
        removeExternalAttachmentAxiosFunc: function (item) {
            let data = {
                attachment_id: this.paramEncoder(item.id), 
                routing_id: this.paramEncoder(this.$route.query.id)
            }
            return new Promise((resolve, reject) => { 
                axios.post(`/routing-su/document-external-attachments/remove`, data, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        resolve(response)
                    } else {
                        this.removing_external_attachment = false;
                        this.removing_external_attachment_id = null;
                        this.$Progress.finish()
                    }
                })
            })
        },
        removeExternalAttachmentAxiosFuncRes: function (item, index) {
            let name = (item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name) + '.' + item.file_ext
            this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: `${name} has been removed!`,
                showConfirmButton: false,
                timerProgressBar: true,
            })
            this.removing_external_attachment = false;
            this.removing_external_attachment_id = null;
            this.d_external_attachments.splice(index, 1)
            this.$Progress.finish()
            return;
        },
        saveExternalAttachment: function () {
            let data = new FormData();
            data.append('document_template_name', this.$route.query.template_name);
            // data.append('document_routing_reference_number', this.$route.query.reference_number);
            data.append('document_routing_reference_number', this.d_informations_backup.reference_number);
            data.append('document_routing_header_id', this.$route.query.id);
            for (let i = 0; i < this.d_external_attachments.length; i++) {
                const element = this.d_external_attachments[i];
                if(!element.id) {
                    data.append('external_attachment[' + i + '][document_routing_header_id]', this.$route.query.id)
                    data.append('external_attachment[' + i + '][file]', element.file)
                    data.append('external_attachment[' + i + '][remarks]', element.remarks)
                    data.append('external_attachment[' + i + '][name]', element.name)
                }
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to save attachment file(s).`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/routing-su/document-external-attachments/add', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `Attachment file(s) has been saved.`,
                            })
                            this.$Progress.finish()
                            this.getDocumentExternalAttachments();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        onFileChange: function (e){
            let files = e.target.files || e.dataTransfer.files;
            if(files.length > 0) {
                for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    if(element.type.split('/')[0] != 'image' 
                        && element.type != 'application/pdf' 
                            && element.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                                && element.type != 'application/vnd.ms-excel') 
                    {
                        this.$swal({
                            customClass: 'ta-left',
                            toast: true,
                            position: 'top-right',
                            timer: 5000,
                            html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>Files Invalid. <br>Only accept pdf, images, and the latest excel file.</h5>
                                </div>`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        continue;
                    }


                    if (element.size / Math.pow(1024,1) > 8000) {
                        this.$swal({
                            customClass: 'ta-left',
                            toast: true,
                            position: 'top-right',
                            timer: 5000,
                            html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>${element.name.length > 10 ? element.name.substring(0, 10) + '...' +  element.name.split('.')[1] : element.name} is  too large. Maximum size per file is 8MB.</h5>
                                </div>`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        continue;
                    } 

                    this.d_external_attachments_key++;
                    this.d_external_attachments.push(
                        {
                            attachment_id:null, 
                            created_at: 'Recently Added',
                            document_routing_header_id: this.$route.query.id,
                            file: element,
                            file_mime: element.type,
                            file_size: element.size,
                            id:null,
                            name: element.name,
                            document_template_category_attachment_checklist: null,
                            remarks: '',
                            url: '#',
                            user: {first_name: this.$store.getters['getUserDetails'].first_name},
                            user_id: this.$store.getters['getUserDetails'].id
                        }
                    )
                }
            }
            
            const input = this.$refs._externalFiles;
            input.type = 'text';
            input.type = 'file';
        },
        removeInternalAttachment: function (parent, parent_index, child, child_index){
            this.d_internal_attachments_key++;
            if(!child.id) {
                let name = name = (child.name.length > 10 ? child.name.substring(0, 10) + '...' : child.name) + '.' + child.file_ext
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'success',
                    title: `${name} has been removed!`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                if(this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == parent.reference_number.toLowerCase())[0].attachments.length == 1) {
                    this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == parent.reference_number.toLowerCase())[0].attachments.splice(child_index, 1);
                    this.d_internal_attachments.splice(parent_index, 1)
                } else {
                    this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == parent.reference_number.toLowerCase())[0].attachments.splice(child_index, 1);
                }
                return;
            }

            this.removing_internal_attachment = true;
            this.removing_internal_attachment_id = child.id;

            if(this.d_informations_backup.status != 3) {
                this.$Progress.start()
                this.removeInternalAttachmentAxiosFunc(child)
                .then(response => { 
                    this.removeInternalAttachmentAxiosFuncRes(parent, parent_index, child, child_index)
                })
            } else {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    html: `When this internal attachment is removed, it will also be deleted from any succeeding routing that was made. This action cannot be undone. <br>Do you still want to continue?`,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return this.removeInternalAttachmentAxiosFunc(child)
                        .then(response => { 
                            this.removeInternalAttachmentAxiosFuncRes(parent, parent_index, child, child_index)
                        })
                    }
                }).then (resp => {
                    this.removing_internal_attachment = false;
                    this.removing_internal_attachment_id = null;
                })
            }
        },
        removeInternalAttachmentAxiosFunc: function (child) {
            let data = {
                attachment_id: this.paramEncoder(child.id), 
                routing_id: this.paramEncoder(this.$route.query.id)
            }
            return new Promise((resolve, reject) => { 
                axios.post(`/routing-su/document-internal-attachments/remove`, data, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        resolve(response)
                    } else {
                        this.removing_internal_attachment = false;
                        this.removing_internal_attachment_id = null;
                        this.$Progress.finish()
                    }
                })
            })
        },
        removeInternalAttachmentAxiosFuncRes: function (parent, parent_index, child, child_index) {
            let name = (child.name.length > 10 ? child.name.substring(0, 10) + '...' : child.name) + '.' + child.file_ext
            this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: `${name} has been removed!`,
                showConfirmButton: false,
                timerProgressBar: true,
            })

            if(this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == parent.reference_number.toLowerCase())[0].attachments.length == 1) {
                this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == parent.reference_number.toLowerCase())[0].attachments.splice(child_index, 1);
                this.d_internal_attachments.splice(parent_index, 1)
            } else {
                this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == parent.reference_number.toLowerCase())[0].attachments.splice(child_index, 1);
            }

            this.removing_internal_attachment = false;
            this.removing_internal_attachment_id = null;
            this.$Progress.finish()
            return;
        },
        getRoutingReferenceNumber: function () {
            if(this.chosen.cat) {
                this.is_getting_rrn = true;
                this.rrnm_key+1;
                this.$Progress.start()
                axios.get('/drs/document-routing/reference-number/'+this.chosen.cat.id, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        this.rrnm_list = response.data.data
                        this.routing_reference_number_modal = true
                        this.is_getting_rrn = false;
                        this.$Progress.finish()
                    }
                })
            }
        },
        rrnm_selected_row: function (item, index, column, event) {
            this.chosen.rrn = item.reference_number
            this.chosen.rrn_id = item.id
            this.routing_reference_number_modal = false
        },
        getFileOfRouting: function (type) {
            this.is_getting_file_to_add_internally = true;
            // let exists = this.d_internal_attachments.filter(x => x.reference_number.toLowerCase() == this.chosen.rrn.toLowerCase());

            // let shorted_rrn = (this.chosen.rrn.length > 10 ? this.chosen.rrn.substring(0, 10) + '...' : this.chosen.rrn)
            // if(exists.length > 0) {
            //     this.is_getting_file_to_add_internally = false;
            //     return this.$swal({
            //         toast: true,
            //         position: 'top-right',
            //         timer: 3000,
            //         icon: 'error',
            //         title: `Reference number <b>${shorted_rrn}</b> already exists.`,
            //         timerProgressBar: true,
            //         showConfirmButton: false,
            //     }) 
            // }
            if(this.d_internal_attachments.filter(x => x.reference_number == this.chosen.rrn).length > 0) {
                this.is_getting_file_to_add_internally = false;
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Reference number <b>${this.chosen.rrn}</b> already exists.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            this.$Progress.start()
            axios.get('/drs/document-routing/attachments/'+this.chosen.rrn_id, {validateStatus: () => true})
            .then(response => {
                this.is_getting_file_to_add_internally = false;
                if ( response.status == 200 ) {
                    let data = response.data.data
                    let parent_file = {
                        id: null,
                        parent: true, 
                        main:false,
                        reference_number: this.chosen.rrn, 
                        attachment_id: null,
                        filename: "", 
                        file:"",
                        filetype: "", 
                        filesize: "", 
                        fileext: "", 
                        remarks: "", 
                        checklist: null,
                        checklist_old_id: null,
                        _classes: 'parent-row',
                        uploaded_by: this.$store.getters['getUserDetails'].first_name,
                        url: null,
                    }
                    this.d_internal_attachments.push(parent_file) // push parent file
                    let main_file = {
                        id: null,
                        parent: false, 
                        main: true,
                        reference_number: data.reference_number,
                        attachment_id: data.id,
                        filename: data.reference_number,
                        file: data.file,
                        filetype:"application/pdf",
                        filesize: this.formatBytes(data.file_size),
                        fileext: "pdf", 
                        remarks: this.chosen.rmks ? this.chosen.rmks : '',
                        checklist: null,
                        checklist_old_id: null,
                        uploaded_by: this.$store.getters['getUserDetails'].first_name,
                        _classes: '',
                        url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${this.chosen.rrn_id}/${data.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                        
                    }
                    this.d_internal_attachments.push(main_file) // push main file
                    if(type == 'all_file') { // all file 
                        let extra_file = data.document_routing_attachments
                        for (let i = 0; i < extra_file.length; i++) {
                            let supporting_file_row = {
                                id: null,
                                parent: false, 
                                main: false,
                                reference_number: data.reference_number,
                                attachment_id: data.id,
                                filename: extra_file[i].name,
                                file: extra_file[i].file,
                                filetype: extra_file[i].file_mime,
                                filesize: this.formatBytes(extra_file[i].file_size),
                                fileext: extra_file[i].file_ext, 
                                remarks: this.chosen.rmks ? this.chosen.rmks : '',
                                checklist: null,
                                uploaded_by: this.$store.getters['getUserDetails'].first_name,
                                checklist_old_id: null,
                                _classes: '',
                                url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${this.chosen.rrn_id}/${data.reference_number}/?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                            }
                            this.d_internal_attachments.push(supporting_file_row); // push supporting file
                        }
                    }
                    this.$Progress.finish()









                    // this.d_internal_attachments_key++;
                    // let rspns = response.data.data;
                    // let new_internal = {
                    //     status: 'new',
                    //     reference_number: this.chosen.rrn,
                    //     remarks: this.chosen.rmks,
                    //     uploaded_by: {first_name: this.$store.getters['getUserDetails'].first_name},
                    //     date_uploaded: '',
                    //     attachments: [],
                    // }
                    // let main_file = {
                    //     name: rspns.reference_number,
                    //     file: rspns.file,
                    //     file_mime: "application/pdf",
                    //     file_ext: "pdf",
                    //     remarks: this.chosen.rmks,
                    //     attachment_id: rspns.id,
                    //     id: null,
                    //     url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${this.chosen.rrn_id}/${rspns.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                    // }
                    // if(type == 'main_file') { // main file only
                    //     new_internal.attachments.push(main_file)
                    // } else { // all files included
                    //     if(rspns.document_routing_attachments.length == 0) {
                    //         this.is_getting_file_to_add_internally = false;
                    //         return this.$swal({
                    //             toast: true,
                    //             position: 'top-right',
                    //             timer: 3000,
                    //             icon: 'error',
                    //             title: `There was no attachment file found other than the main file.`,
                    //             timerProgressBar: true,
                    //             showConfirmButton: false,
                    //         }) 
                    //     }
                    //     new_internal.attachments.push(main_file)
                    //     for (let i = 0; i < rspns.document_routing_attachments.length; i++) {
                    //         const element = rspns.document_routing_attachments[i];
                    //         let other_file = {
                    //             name: element.name,
                    //             file: element.file,
                    //             file_mime: element.file_mime,
                    //             file_ext: element.file_ext,
                    //             remarks: this.chosen.rmks,
                    //             attachment_id: rspns.id,
                    //             id: null,
                    //             url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${this.chosen.rrn_id}/${rspns.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                    //         }
                    //         new_internal.attachments.push(other_file);
                    //     }
                    // }
                    // this.d_internal_attachments.push(new_internal)  
                    this.chosen = this.clearGettingFileToAddInternallyForm();
                }
            })
        },
        saveInternalAttachment: function () {
            let tmp_files = this.d_internal_attachments.filter(x => !x.id && !x.parent);
            let files = []
            for (let index = 0; index < tmp_files.length; index++) {
                const element = tmp_files[index];
                files.push({
                    attachment_id: element.attachment_id,
                    file: element.file,
                    file_ext: element.fileext,
                    file_mime: element.filetype,
                    id: null,
                    name: element.filename,
                    remarks: element.remarks,
                    url: element.url,
                })
            }

        
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to save attachment file(s).`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                preConfirm: () => { 
                    let data = {
                        document_routing_header_id: this.$route.query.id,
                        internal_attachment: files
                    }
                    this.$Progress.start()
                    return axios.post('/routing-su/document-internal-attachments/add', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `Attachment file(s) has been saved.`,
                            })
                            this.$Progress.finish()
                            this.getDocumentInternalAttachments();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        removeInternalFileV2(type, item, index) {
            this.removing_internal_attachment = true;
            this.removing_internal_attachment_id = item.id;
            this.d_internal_attachments_key++;
            let group_internal_base_on_item = this.d_internal_attachments.filter(f => f.reference_number == item.reference_number)
            // if(type == 'parent_file') { // all file
            //     let all_item_with_id = group_internal_base_on_item.filter(t => t.id).map(y => y.id);
            //     for (let f = 0; f < all_item_with_id.length; f++) {
            //         const element = all_item_with_id[f];
            //         internal_attachment_to_remove.push(element);
            //     }

            //     let get_checklist_old_id_in_the_childs = group_internal_base_on_item.filter(j => j.checklist_old_id).map(j => j.checklist_old_id);
            //     for (let y = 0; y < get_checklist_old_id_in_the_childs.length; y++) {
            //         const element = get_checklist_old_id_in_the_childs[y];
            //         // DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(element))
            //     }
            //     this.d_internal_attachments = this.d_internal_attachments.filter(x => x.reference_number != item.reference_number);
            // } else if (type == 'main_file') { // main file
            if(item.id) {
                if(this.d_informations_backup.status == 3) {
                    this.$swal({
                        icon: "warning",
                        title: "Are you sure?",
                        html: `When this internal attachment is removed, it will also be deleted from any succeeding routing that was made. This action cannot be undone. <br>Do you still want to continue?`,
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes",
                        showLoaderOnConfirm: true,
                        preConfirm: () => { 
                            this.$Progress.start()
                            return this.removeInternalAttachmentAxiosFunc(item)
                            .then(response => { 
                                this.$Progress.finish()
                                this.$swal({
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    icon: 'success',
                                    title: `${item.filename} has been removed!`,
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                })
                                if(group_internal_base_on_item.length == 2) {
                                    this.d_internal_attachments = this.d_internal_attachments.filter(h => h.reference_number != item.reference_number)
                                } else {
                                    this.d_internal_attachments.splice(index, 1)
                                }
                            })
                        }
                    }).then (resp => {
                        this.removing_internal_attachment = false;
                        this.removing_internal_attachment_id = null;
                        return;
                    })
                } else {
                    this.$Progress.start()
                    this.removeInternalAttachmentAxiosFunc(item)
                    .then(response => { 
                        this.$Progress.finish()
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `${item.filename} has been removed!`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        if(group_internal_base_on_item.length == 2) {
                            this.d_internal_attachments = this.d_internal_attachments.filter(h => h.reference_number != item.reference_number)
                        } else {
                            this.d_internal_attachments.splice(index, 1)
                        }
                    })
                }
                return;
            } 
            if(group_internal_base_on_item.length == 2) {
                this.d_internal_attachments = this.d_internal_attachments.filter(h => h.reference_number != item.reference_number)
            } else {
                this.d_internal_attachments.splice(index, 1)
            }
            if(!item.id) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'success',
                    title: `${item.filename} has been removed!`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                this.removing_internal_attachment = false;
            }
        },
        removeRemarks: function (item, index) {
            this.d_remarks_key++;
            if(!item.id) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'success',
                    title: `Remarks of ${item.user.first_name} has been removed!`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                this.d_remarks.splice(index, 1)
                return;
            }

            this.removing_remarks = true;
            this.removing_remarks_id = item.id
            this.$Progress.start()
            axios.post(`/routing-su/document-remarks/remove`, {remarks_id: this.paramEncoder(item.id), routing_id: this.$route.query.id}, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `Remarks of ${item.user.first_name} has been removed!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.removing_remarks = false;
                    this.removing_remarks_id = null;
                    this.d_remarks.splice(index, 1)
                    this.$Progress.finish()
                    return;
                } else {
                    this.removing_remarks = false;
                    this.removing_remarks_id = null;
                    this.$Progress.finish()
                }
            })
        },
        addRemarks: function () {
            this.d_remarks_key++;
            let new_remarks = {
                document_routing_header_id: this.$route.query.id,
                id: null,
                remarks:'',
                user: {first_name: this.$store.getters['getUserDetails'].first_name},
                user_id: this.$store.getters['getUserDetails'].id,
            }
            this.d_remarks.push(new_remarks)
        },
        saveRemarks: function (type, item=null, index=null) {
            if((item && !item.remarks) || (!item && !this.d_remarks.filter(x => !x.id)[0].remarks)) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Remarks cannot be empty.`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }) 
            }

            let data = {
                remarks_id: item ? this.paramEncoder(item.id) : null,
                remarks: item ? item.remarks : this.d_remarks.filter(x => !x.id)[0].remarks,
                document_routing_header_id: this.$route.query.id,
            }
            if(type == 'by-row') {
                this.saving_remarks = true;
                this.saving_remarks_id = item.id
                axios.post('/routing-su/document-remarks/add', data, {validateStatus: () => true})
                .then(response => {
                    if ( response.status == 200 ) {
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'success',
                            title: `Remarks of ${this.$store.getters['getUserDetails'].first_name} successfully saved!`,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        }) 
                        this.$Progress.finish()
                        this.saving_remarks = false;
                        this.saving_remarks_id = null
                        this.getDocumentRemarks();
                        return;
                    } else {
                        this.saving_remarks = false;
                        this.saving_remarks_id = null
                    }
                })
                return;
            } else {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `You are trying to save the remarks added.`,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/routing-su/document-remarks/add', data, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-right',
                                    timer: 3000,
                                    icon: 'success',
                                    title: `Remarks of ${this.$store.getters['getUserDetails'].first_name} successfully saved!`,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                }) 
                                this.$Progress.finish()
                                this.getDocumentRemarks();
                                return;
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
            }
        },
        removeShared: function (item, index) {
            this.removing_shared = true;
            this.removing_shared_id = item.id
            let data = {
                document_routing_header_id: this.$route.query.id,
                id: this.paramEncoder(item.id),
                shared_by_user_id: this.paramEncoder(item.shared_by_user_id)
            }
            this.$Progress.start()
            axios.post(`/routing-su/document-shared-users/remove`, data, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.shared_to_user.first_name} has been removed in the list.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.removing_shared = false;
                    this.removing_shared_id = null;
                    this.d_users_shared.splice(index, 1)
                    this.$Progress.finish()
                    return;
                } else {
                    this.removing_shared = false;
                    this.removing_shared_id = null;
                    this.$Progress.finish()
                }
            })
        },
        addUser: function () {
            let selected_id = [];
            selected_id = this.d_shared_users_signatory_and_creator_id;
            this.d_users_shared.forEach(function(obj){
                 selected_id.push(parseInt(obj.shared_to_user_id));
             })

            this.$refs.usersSelectableModal.users_selectable_modal = true,
            this.$emit('show_users_selectable_modal', selected_id);
        },
        userSelected: function (object) {
             let _data = {
                document_routing_header_id: this.$route.query.id,
                share: []
            }
            if (object.length > 0) {
                for (let i = 0; i < object.length; i++) {
                   let _row = {
                        shared_by_user_id: this.paramEncoder(this.$store.getters['getUserDetails'].id),
                        shared_to_user_id: this.paramEncoder(object[i]),
                   }
                   _data.share.push(_row)
                }
            }
            this.$Progress.start()
            axios.post('/routing-su/document-shared-users/add', _data, {validateStatus: () => true}).then(response => {
                if ( response.status == 200 ) {
                    this.$emit('show_users_selectable_modal_closed');
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `User(s) added successfully!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    
                    this.$Progress.finish()
                    this.getDocumentSharedUsers()
                }
            })
        },
        cancelDocumentRouting: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying to cancel the routing.</h4>
                Please be certain, Once this routing has been <b>cancelled</b> it is unable to be <i>undone</i>.`,
                input: 'textarea',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Enter remarks to proceed",
                showLoaderOnConfirm: true,
                preConfirm: (cancel_remarks) => { 
                    this.$Progress.start()
                    if(!cancel_remarks) {
                        this.$swal.showValidationMessage('Remarks cannot be empty.')
                        this.$Progress.fail()
                        return;
                    }

                    if(cancel_remarks.length < 5 ) {
                        this.$swal.showValidationMessage('Remarks must not be less than 5 Characters.')
                        this.$Progress.fail()
                        return;
                    }
                    let data = {
                        routing_id: this.$route.query.id,
                        remarks: cancel_remarks
                    }
                    return axios.post('/routing-su/document-cancel', data, {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.d_informations.reference_number}</b> routing has been cancelled!`,
                            })

                            let dr_id = this.$route.query.id;
                            // let reference_number = this.$route.query.reference_number;
                            let reference_number = this.d_informations_backup.reference_number;
                            let template_name = this.$route.query.template_name;
                            this.$router.replace({query: {id: dr_id, reference_number: reference_number, status: 4, template_name: template_name}})
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        returnToDraft: function () {
            this.returning_to_draft = true
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying return this document routing to draft.</h4>
                Please be certain this action is unable to be <i>undone</i>. <br>
                <br>
                Add reason to continue.`,
                input: 'textarea',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Enter reason to proceed",
                showLoaderOnConfirm: true,
                preConfirm: (return_reason) => { 
                    this.$Progress.start()
                    if(!return_reason) {
                        this.$swal.showValidationMessage('Reason cannot be empty.')
                        this.$Progress.fail()
                        return;
                    }

                    if(return_reason.length < 5 ) {
                        this.$swal.showValidationMessage('Reason must not be less than 5 Characters.')
                        this.$Progress.fail()
                        return;
                    }
                    let data = {
                        reason: return_reason,
                        document_routing_header_id: this.$route.query.id,
                        document_routing_line_id: this.paramEncoder(this.d_template_and_signatories.signatories[0].id),
                    }
                    return axios.post('/routing-su/document-template-and-signatories/return-to-draft', data, 
                    {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {

                            let dr_id = this.$route.query.id;
                            // let reference_number = this.$route.query.reference_number;
                            let reference_number = this.d_informations_backup.reference_number;
                            let template_name = this.$route.query.template_name;
                            this.$Progress.finish()

                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${reference_number}</b> routing has successfully returned to draft.`,
                            })
                            this.returning_to_draft = false
                            this.$router.replace({query: {id: dr_id, reference_number: reference_number, status: 1, template_name: template_name}})
                        }
                    })
                }
            }).then( res => {
                this.returning_to_draft = false
            })
        },
        returnToSignatory: function () {
            this.return_to_signatory_modal = true;
            this.returning_to_signatory = true
        },
        submitReturnToSignatory: function () {
            let returned_to_first_signatory = false;
            let data = {
                reason: this.return_document.reason,
                document_routing_header_id: this.paramEncoder(this.return_document.user.document_routing_header_id),
                document_routing_line_id: this.paramEncoder(this.return_document.user.document_routing_line_id),
            }
            let message = `You are trying to return this document to <b>${this.return_document.user.name}</b>.
                            <br> This action will remove all signatures after and including <i>${this.return_document.user.name}</i>.`;
            if(this.first_signatory_line_id == data.document_routing_line_id) {
                returned_to_first_signatory = true;
                message = `You are trying to return this document to <b>${this.return_document.user.name}</b>.
                            <br>Please be certain. This action will also <b>remove</b> <u>all the signatures</u> added to the document.`
            }
            let dr_id = this.$route.query.id;
            // let reference_number = this.$route.query.reference_number;
            let reference_number = this.d_informations_backup.reference_number;
            let template_name = this.$route.query.template_name;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: message,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/routing-su/document-template-and-signatories/return-to-signatory', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$Progress.finish()
                            this.return_to_signatory_modal = false;
                            this.getDocumentTemplateAndSignatories();
                            if(returned_to_first_signatory) {
                                this.$router.replace({query: {id: dr_id, reference_number: reference_number, status: 1, template_name: template_name}})
                            }
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${reference_number}</b> routing has successfully returned.`,
                            })
                        }
                    })
                },
                allowOutsideClick: () => false
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        // --------------------------------------------- custom function
        startLoading: function() {
            this.$Progress.start()
            this.data_loading = true;
        },
        endLoading: function () {
            this.data_loading = false;
            this.$Progress.finish()
        },
        toggleDetails:function(item, index) {
            this.$set(this.d_template_and_signatories.signatories[index], '_toggled', !item._toggled)
        },
        toggleInternalAttachment: function(item, index) {
            this.$set(this.d_internal_attachments[index], '_toggled', !item._toggled)
        },
        encodedAndCleanFileName: function (file_name) { 
            if(file_name) { 
                let encodedFileName = encodeURI(file_name);
                let encodedAndCleanFileName = encodedFileName.replace(/#(?=\S)/g, '%23')
                return encodedAndCleanFileName;
            }
            return null;
        },
        toggleEdit: function() {
            this.edit_info = !this.edit_info
        },
        clearDocumentInformations: function () {
            return {
                status: null,
                reference_number: '',
                description: '',
                document_file: []
            }
        },
        clearGettingFileToAddInternallyForm: function () {
            return {
                cat: null,
                rrn: null,
                rrn_id: null,
                rmks: '',
            }
        },
        clearChangeSignatory: function () {
            return {
                selected: null,
                selected_row_id: null,
                new_signatory:null,
                selected_requirements:[],
                reason: null,
            }
        },
        clearAddSignatory: function () {
            return {
                new_signatory:null,
                selected_requirements:[],
                order: null
            }
        },
        clearReturnSignatory: function () {
            return {
                user: null,
                reason:null,
            }
        }
    },
    watch: {
        change_of_signatory_modal: function (value) {
            if(!value) {
                this.change_signatory = this.clearChangeSignatory();
                this.changing_signatory = false;
                this.changing_signatory_id = null;
            }
        },
        add_signatory_modal: function (value) {
            if(!value) {
                this.adding_signatory = false
                this.add_signatory = this.clearAddSignatory()
            }
        },
        active_tab: function (value) {
            switch (value) {
                case 0: 
                    this.edit_info = false;
                    this.d_informations = this.clearDocumentInformations();
                    this.getDocumentInformations();
                    break;
                case 1: 
                    this.getDocumentTemplateAndSignatories();
                    break;
                case 2:
                    this.getDocumentExternalAttachments();
                    break;
                case 3:
                    this.chosen = this.clearGettingFileToAddInternallyForm(); 
                    this.getDocumentInternalAttachments();
                    this.getDocumentCategory();
                    break;
                case 4:
                    this.getDocumentRemarks();
                    break;
                case 5:
                    this.getDocumentSharedUsers();
                    break;
                case 6:
                    this.getDocumentTimeLine();
                    break;

            }
        },
        return_to_signatory_modal: function (value) {
            if(!value) {
                this.returning_to_signatory = false
                this.return_document = this.clearReturnSignatory();
                
            }
        }
    }
}
</script>

<style>
.td-fxd-wdth {
    width: 72px !important;
}
.td-actn-fxd-wdth {
    width: 150px !important
}
.parent-row td {
    border-left: none;  /* Remove left border of each cell */
    border-right: none; /* Remove right border of each cell */
}
.parent-row-light {
    background: lightgray;
}
.parent-row-dark {
    background: #a9a9a97a;
}
.parent-row-light td:first-child {
    border-left: 1px solid #D8DBE0; /* Add left border to the first cell */
}
.parent-row-light td:last-child {
    border-right: 1px solid #D8DBE0; /* Add right border to the last cell */
}
.parent-row-dark td:first-child {
    border-left: 1px solid #3A3B44; /* Add left border to the first cell */
}
.parent-row-dark td:last-child {
    border-right: 1px solid #3A3B44; /* Add right border to the last cell */
}

.th-fixed-width {
    width: 200px !important;
}

.dragging {
  background-color: grey !important;
  opacity: 0.5;
}

.drag-over {
  background-color: lightblue !important; 
}

.drop-location {
  border-top: 2px dashed lightyellow !important; 
}
</style>